import Table from '@material-ui/core/Table';
import React from 'react';

import { theme } from '../styles/theme';
import { withStyles } from '@material-ui/core/styles';



const styles = media => ({
  cssLabel: {
    [media.breakpoints.down('lg')]: {
      fontSize: '0.8em',
      marginBottom:'10px'
    },
    fontSize: theme.fontSizeSm,
    color: theme.colors.grey3,
    fontFamily: theme.fontFamilyBody,
    overflowX: 'auto'
  },
});

const MaterialTable = props => {
  const { classes, ...other } = props;

  return (
    <Table
      {...other}
      margin="dense"
      //padding="none"
      classNames={classes.cssLabel}
    />
  );
};

// All material table props
MaterialTable.propTypes = {};

export default withStyles(styles)(MaterialTable);
