import React, { useState, useEffect } from 'react';
import { useFormState } from 'react-use-form-state';
import styled from 'styled-components';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import Select from 'react-select';
import {
  MaterialInput,
  //MaterialSelect,
  //MaterialMenuItem,
  Button,
  Loader,
} from '../../components';
import { theme } from '../../styles/theme';
import Arabesco from '../../images/arabescos.svg';
import { medias } from '../../utils/mediaQueries';
import { hexToRgb } from '../../utils/hexToRgb';
import { urls } from '../../services/urls';
import useFetch from '../../hooks/useFetch';
import { siteContent, userData } from '../../services/api';

const AssetContainer = styled.div`
  @media ${medias.tabletMin} {
    background-size: contain;
  }

  background-image: url(${Arabesco});
  background-size: cover;
  background-repeat-x: repeat;
  width: 100%;
  height: 2em;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  ${({ bottom }) => bottom && `margin-top: 1em;`}
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 2em;

  background-color: ${theme.colors.white};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 3px 10px 0px ${hexToRgb(theme.colors.grey2, 0.5)};
`;

const ButtonsContainer = styled.section`
  display: flex;
  justify-content: space-around;
  margin-top: 0.5em;
  width: 100%;
`;

const StyledForm = styled.form`
  width: 100%;
  padding: 0 1em;
`;

const StyledLabel = styled.label`
  color: ${theme.colors.grey3};
  font-size: 0.8em;
  margin: 0;
  text-align: left;
`;

const StyledRow = styled.div`
  margin-top: 0.2em;
  padding: 0.8em 0;
`;

const StyledButton = styled(props => <Button {...props} />)`
  width: 80%;
  background-color: ${theme.colors.text_heading};
  border-color: ${theme.colors.text_heading};
  color: white;
  font-size: ${theme.fontSizeSm}px;
  margin-top: 2em;
`;

const StyledInput = styled(props => <InputMask {...props} />)`
  width: 100%;
  font-size: 1em;
`;

const DataForm = ({ nextStep, states, brideWelcomeForm, dispatch }) => {
  const [loading, setLoading] = useState(true);
  const [loadingCities, setLoadingCities] = useState(false);
  const [availableCities, setAvailableCities] = useState(null);
  const [filteredCities, setFilteredCities] = useState(null);
  const [filteredUf, setFilteredUf] = useState(null);
  const [city, setCity] = useState(null);
  const [uf, setUf] = useState(null);
  const [defaultUf, setDefaultUf] = useState('');
  const [defaultCity, setDefaultCity] = useState('');
  const [formState, { text, /*select,*/ label }] = useFormState(null, {
    withIds: true,
    onChange(e) {
      const { name, value } = e.target;
      dispatch({
        type: 'changeBrideWelcomeForm',
        field: name,
        newText: value,
      });

      if (name === 'uf') {
        fetchCities(value);
      }
    },
  });

  const fetchCities = async codUf => {
    setLoadingCities(true);
    setFilteredCities(null);
    setAvailableCities(null);
    const response = await siteContent.cities(codUf);
    response.ok && response.data
      ? setAvailableCities(response.data)
      : toast.error('Não foi possivel recuperar as cidades!');
    setLoadingCities(false);
  };

  useFetch({
    url: urls.userdata.userInfo,
    method: 'get',
    condition: () => loading,
    callback: data => {
      if (data) {
        const { name, email, codIBGE, codUf, phone, cellphone } = data;
        fetchCities(codUf);
        const userData = {
          name,
          email,
          phone,
          cellphone,
          //uf: codUf,
          //city: codIBGE,
        };

        dispatch({
          type: 'setBrideDataForm',
          newForm: userData,
        });
        if (codUf) {
          setUf(codUf);
        }
        if (codIBGE) {
          setCity(codIBGE);
        }
        Object.keys(userData).forEach(key =>
          formState.setField(key, userData[key])
        );
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    if (states && uf) {
      const filtered = states.filter(({ cod }) => cod === uf);
      if (filtered && filtered.length) {
        setDefaultUf(filtered[0].name);
      }
    }
  }, [states, uf]);

  useEffect(() => {
    if (uf && availableCities && city && !defaultCity) {
      const filtered = availableCities.filter(
        ({ codCidadeIBGE }) => codCidadeIBGE === city
      );
      if (filtered && filtered.length) {
        setDefaultCity(filtered[0].nomeCidade);
      }
    }
  }, [availableCities, city, uf, defaultCity]);

  const onSubmitForm = async () => {
    const payload = formState.values;
    delete payload.uf;
    delete payload.city;
    payload.city = city;
    const response = await userData.setBasicData(payload);
    if (response.ok) {
      toast.info('Dados atualizados com sucesso!');
      return nextStep();
    }
    toast.error('Houve um erro na submissão.');
  };

  const handleNextClick = () => {
    const { name, email, cellphone } = brideWelcomeForm;
    !name || !email || !cellphone || !city || !uf
      ? toast.error('Preencha todos os campos!')
      : onSubmitForm();
  };

  const handleInputCity = str => {
    if (str.length === 0) {
      setFilteredCities(null);
      return;
    }
    const suggestions = availableCities.filter(
      item =>
        item.nomeCidade.substr(0, str.length).toLowerCase() ===
        str.toLowerCase()
    );
    if (suggestions) {
      setFilteredCities(suggestions);
    }
  };

  const handleCityChange = str => {
    if (!str) {
      return;
    }
    setCity(str.value);
  };

  const mapCities = cities => [
    ...cities.map(({ nomeCidade, codCidadeIBGE }) => ({
      label: nomeCidade,
      value: codCidadeIBGE,
    })),
  ];

  const handleUfChange = str => {
    if (!str) {
      return;
    }
    setAvailableCities(null);
    setDefaultCity('');
    setCity('');
    setUf(str.value);
    fetchCities(str.value);
  };

  const mapUf = states => [
    ...states.map(({ name, cod }) => ({
      label: name,
      value: cod,
    })),
  ];

  const handleInputUf = str => {
    if (str.length === 0) {
      setFilteredUf(null);
      return;
    }
    const suggestions = states.filter(
      item =>
        item.name.substr(0, str.length).toLowerCase() === str.toLowerCase()
    );
    if (suggestions) {
      setFilteredUf(suggestions);
    }
  };

  return (
    <div style={{ alignItems: 'center' }}>
      <Card className="bootstrap-wrapper">
        <AssetContainer />
        <StyledForm>
          <MaterialInput
            {...text('name')}
            style={{ width: '100%' }}
            required
            label="Seu nome"
          />
          <MaterialInput
            {...text('email')}
            style={{ width: '100%' }}
            required
            label="Seu melhor e-mail"
          />
          <div className="row">
            <div className="col-md-6">
              <StyledInput
                {...text('phone')}
                label="Telefone"
                mask="(99) 9999-9999"
              >
                {inputProps => <MaterialInput {...inputProps} />}
              </StyledInput>
            </div>

            <div className="col-md-6">
              <StyledInput
                {...text('cellphone')}
                required
                label="Celular"
                mask="(99) 99999-9999"
              >
                {inputProps => <MaterialInput {...inputProps} />}
              </StyledInput>
            </div>
          </div>
          {/* <InputsSection>
                  <InputMask
                    {...text('phone')}
                    label="Telefone"
                    mask="(99) 9999-9999"
                  >
                    {inputProps => <MaterialInput {...inputProps} />}
                  </InputMask>
                  <InputMask
                    {...text('cellphone')}
                    required
                    label="Celular"
                    mask="(99) 99999-9999"
                  >
                    {inputProps => <MaterialInput {...inputProps} />}
                  </InputMask>
                </InputsSection> */}
          {!loading && (
            <>
              {' '}
              <StyledRow className="row">
                <div className="col-md-12">
                  <StyledLabel {...label('uf')}>Estado</StyledLabel>
                  {/*<MaterialSelect
                  {...select({
                    name: 'uf',
                    validate: () => {},
                  })}
                >
                  {states
                    ? states.map(({ name, cod }) => (
                        <MaterialMenuItem key={cod} value={cod}>
                          {name}
                        </MaterialMenuItem>
                      ))
                    : null}
                    </MaterialSelect>*/}
                  <Select
                    options={
                      filteredUf
                        ? mapUf(filteredUf)
                        : states
                        ? mapUf(states)
                        : null
                    }
                    placeholder="Digite seu estado..."
                    onInputChange={handleInputUf}
                    defaultValue={uf || null}
                    defaultInputValue={defaultUf}
                    isClearable
                    isDisabled={false}
                    onChange={handleUfChange}
                  />
                </div>
              </StyledRow>
              <StyledRow className="row">
                <div className="col-md-12">
                  {!loadingCities && availableCities ? (
                    <>
                      <StyledLabel {...label('city')}>Cidade</StyledLabel>
                      <Select
                        options={
                          filteredCities
                            ? mapCities(filteredCities)
                            : availableCities
                            ? mapCities(availableCities)
                            : null
                        }
                        defaultValue={city || null}
                        defaultInputValue={defaultCity}
                        placeholder="Digite sua cidade..."
                        onInputChange={handleInputCity}
                        isClearable
                        isDisabled={!uf}
                        onChange={handleCityChange}
                      />
                    </>
                  ) : (
                    <p>Aguarde...</p>
                  )}
                </div>
              </StyledRow>
            </>
          )}
          {/* <InputsSection>
                  <SelectorContainer>
                    <FormControl style={{ width: '100%' }}>
                      <StyledLabel {...label('uf')}>Estado</StyledLabel>
                      <StyledSelect
                        {...select({
                          name: 'uf',
                          validate: () => { },
                        })}
                      >
                        {states
                          ? states.map(({ name, cod }) => (
                            <MaterialMenuItem key={cod} value={cod}>
                              {name}
                            </MaterialMenuItem>
                          ))
                          : null}
                      </StyledSelect>
                    </FormControl>
                  </SelectorContainer>
                  <SelectorContainer>
                    <FormControl style={{ width: '100%' }}>
                      <StyledLabel {...label('city')}>Cidade</StyledLabel>
                      <StyledSelect
                        disabled={!formState.values.uf}
                        {...select({
                          name: 'city',
                          validate: () => { },
                        })}
                      >
                        {availableCities
                          ? availableCities.map(({ nomeCidade, codCidadeIBGE }) => (
                            <MaterialMenuItem
                              key={codCidadeIBGE}
                              value={codCidadeIBGE}
                            >
                              {nomeCidade}
                            </MaterialMenuItem>
                          ))
                          : null}
                      </StyledSelect>
                    </FormControl>
                  </SelectorContainer>
                </InputsSection> */}{' '}
          {loading && <Loader />}
          <ButtonsContainer>
            <StyledButton text="AVANÇAR" click={handleNextClick} />
          </ButtonsContainer>
        </StyledForm>
        <AssetContainer bottom />
      </Card>
    </div>
  );
};

export default DataForm;
