import React, { useState } from 'react';
import styled from 'styled-components';

import { Title } from '../components/styled';
import { Tabs } from '../components';
import {
  DataForm,
  MarriageForm,
  HiredOutForm,
} from '../containers/BrideWelcomeForm';
import { useWindowWidth } from '../hooks';
import { medias } from '../utils/mediaQueries';
import useFetch, { method } from '../hooks/useFetch';
import { urls } from '../services/urls';
import { useStateValue } from '../state';
import { PageTitle } from '../components/pageComponents';

const tabsContent = [
  {
    id: 0,
    title: 'SEUS DADOS',
  },
  {
    id: 1,
    title: 'SEU CASAMENTO',
  },
  {
    id: 2,
    title: 'SEGMENTOS FECHADOS',
  },
];

const Wrapper = styled.div`
  width: 90%;
  margin: 1rem auto 6rem;
  padding: 0 1rem;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledTitle = styled(props => <Title {...props} />)`
  @media ${medias.phoneMax} {
    margin: 0%;
  }
`;

const BrideWelcomePage = () => {
  const screenWidth = useWindowWidth();
  const [selectedTab, setSelectedTab] = useState(0);

  const [
    {
      segments: { segments },
      generalData: { states },
      forms: { brideWelcomeForm },
    },
    dispatch,
  ] = useStateValue();

  useFetch({
    url: urls.siteContent.segments,
    method: method.GET,
    condition: () => !segments,
    callback: data =>
      dispatch({
        type: 'setSegments',
        newSegments: data,
      }),
  });

  useFetch({
    url: urls.siteContent.states,
    method: method.GET,
    condition: () => (!states ? true : false),
    callback: data =>
      dispatch({
        type: 'setStates',
        newStates: data,
      }),
  });

  const components = [
    <DataForm
      key={0}
      nextStep={() => setSelectedTab(1)}
      states={states}
      brideWelcomeForm={brideWelcomeForm}
      dispatch={dispatch}
    />,
    <MarriageForm
      key={1}
      nextStep={() => setSelectedTab(2)}
      stepBack={() => setSelectedTab(0)}
      states={states}
      brideWelcomeForm={brideWelcomeForm}
      dispatch={dispatch}
    />,
    <HiredOutForm key={2} stepBack={() => setSelectedTab(1)} />,
  ];

  return (
    <Wrapper>
      <PageTitle>
        {screenWidth > 760 ? (
          <Title>BEM VINDA(O) À REDE VESTIDAS DE BRANCO!</Title>
        ) : (
            <div>
              <StyledTitle>BEM VINDA(O) À REDE </StyledTitle>
              <StyledTitle>VESTIDAS DE BRANCO!</StyledTitle>
            </div>
          )}
      </PageTitle>
      <Tabs
        content={tabsContent}
        onClick={() => { }}
        components={components}
        activeTab={selectedTab}
        disabled
      />
    </Wrapper>
  );
};

export default BrideWelcomePage;
