import React, { useRef } from 'react';
import styled from 'styled-components';
import {
  PageTitle,
  PageWrapper,
  CenteredText,
} from '../components/pageComponents';
import CardContainer from '../components/CardContainer';
import MaterialTable from '../components/MaterialTable';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {
  Tabs,
  Loader,
  Pagination,
  //SearchBar,
  //BrideFilterModal,
  Pagination2,
  TablePagination,
  MaterialInput,
  Button,
} from '../components';
import theme from '../styles/theme';
import { supplierContent } from '../services/api';
import SupplierBridesCard from '../components/SupplierBrideCard';
import SupplierGenerateBrideRow from '../components/SupplierGenerateBrideRow';
import useFetch, { method } from '../hooks/useFetch';
import { urls } from '../services/urls';
import { scrollToRef } from '../hooks';
import { toast } from 'react-toastify';
const tabsContent = [
  {
    id: 0,
    title: 'PONTUAR',
  },
];

const columns = [
  { id: 'key', label: 'Nº', minWidth: '5px' },
  { id: 'date', label: 'DATA', minWidth: '7px' },
  { id: 'bride', label: 'NOIVO', minWidth: '12vw' },
  { id: 'email', label: 'EMAIL', minWidth: '9vw' },
  { id: 'contracts', label: 'PONTUAR', minWidth: '5vw' },
];

const StyledTabs = styled(props => <Tabs {...props} />)`
  margin-top: 1em;
`;

const StyledDiv = styled.div`
  width: 95%;
  display: flex;
  justify-content: space-between;
`;

const StyledSpan = styled.span`
  font-weight: bold;
  color: ${theme.colors.primary};
`;

const TableCard = styled.div`
  width: auto;
  border: 1px dotted black;
  overflow-x: scroll;
  white-space: nowrap;
  font-family: ${theme.fontFamilyBody};
`;

const StyledButton = styled(Button)`
  background-color: ${theme.colors.text_heading};
  border: 2px solid ${theme.colors.text_heading};
  color: white;
  height: 2em;
  width: 35%;
  margin-top: 1em;
  padding: 0;
`;

const SupplierPointBridesPage = () => {
  const [initialPage, setInitialPage] = React.useState(1);
  const order = 'eventDate';

  const [avaliableBrides, setAvaliableBrides] = React.useState(null);
  const [reportAvaliableData, setReportAvaliableData] = React.useState(null);

  const [activeTab, setActiveTab] = React.useState(0);
  const [search, setSearch] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [regionData, setRegionData] = React.useState(false);
  const [segmentData, setSegmentsData] = React.useState(false);
  const [otherSuppliers, setOtherSuppliers] = React.useState(false);
  const [errorOrEmpty, setErrorOrEmpty] = React.useState(false);

  const [cardDisplay, setDisplay] = React.useState(false);

  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);

  const resPerPage = 10;

  /*const doUpdate = useFetch({
    url: urls.supplierContent.getBridesFilteredByType(order, 'pontuar', 1, resPerPage),
    method: method.GET,
    condition: () => !avaliableBrides,
    callback: data => changeState(data),
  });*/

  useFetch({
    url: urls.supplierContent.supplierRegions,
    method: method.GET,
    condition: () => !regionData,
    callback: data => setRegionData(data),
  });

  useFetch({
    url: urls.supplierContent.getOtherSuppliers,
    method: method.GET,
    condition: () => !otherSuppliers,
    callback: data => setOtherSuppliers(data),
  });

  useFetch({
    url: urls.supplierContent.supplierSegments,
    method: method.GET,
    condition: () => !regionData,
    callback: data => setSegmentsData(data),
  });

  const changeState = available => {
    if (available) {
      setAvaliableBrides(
        available !== undefined ? available.eligibleEvents : undefined
      );
      setReportAvaliableData(
        available !== undefined ? available.count : undefined
      );
    }
  };

  const updateOportunities = () => {
    setAvaliableBrides(null);
    //doUpdate();
  };

  const renderAvaliable = (bride, index) => (
    <SupplierBridesCard
      listIndex={index}
      key={bride.id}
      cardData={bride}
      type={0}
      segmentData={segmentData}
      regionsData={regionData}
      updateOportunities={updateOportunities}
      MarkClick={() => {}}
    />
  );

  const renderAvailableRow = (bride, index) => (
    <SupplierGenerateBrideRow
      key={bride.id}
      cardData={bride}
      type={0}
      listIndex={index}
      segmentData={segmentData}
      regionsData={regionData}
      updateOportunities={updateOportunities}
    />
  );

  const CardComponent = ({ data, type }) =>
    data && data.length > 0 ? (
      type === 0 ? (
        <Pagination
          itemsPerPage={10}
          data={data}
          renderItem={renderAvaliable}
          showButtons={false}
        />
      ) : (
        ''
      )
    ) : (
      <CenteredText>Nenhum noivo disponivel!</CenteredText>
    );

  const TableComponent = ({ data, type }) =>
    data && data.length > 0 && data !== undefined ? (
      <TableCard>
        <MaterialTable>
          <TableHead>
            <TableRow>
              {columns.map(col => (
                <TableCell
                  key={col.id}
                  style={{ paddingLeft: '0.6rem !important' }}
                >
                  {col.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {type === 0 ? (
              <TablePagination
                itemsPerPage={10}
                data={data}
                renderItem={renderAvailableRow}
                showButtons={false}
              />
            ) : (
              ''
            )}
          </TableBody>
        </MaterialTable>
      </TableCard>
    ) : (
      <CenteredText>Nenhum noivo disponivel!</CenteredText>
    );

  const components = [
    <CardContainer key={0}>
      <CardComponent data={avaliableBrides} type={0} />
    </CardContainer>,
  ];

  const tableComponents = [
    <CardContainer key={0}>
      <TableComponent data={avaliableBrides} type={0} />
    </CardContainer>,
  ];

  const onPageChange = pageNumber => {
    setIsLoading(true);
    setInitialPage(pageNumber);
    handleSearch(pageNumber);
  };

  const handleTabChange = tab => {
    setActiveTab(tab);
    //setInitialPage(1);
  };

  const handleSearch = async pageNumber => {
    setErrorOrEmpty(false);
    let response;

    if (!search) {
      toast.info('Preencha o campo de pesquisa!');
      return;
    }
    setIsLoading(true);
    if (search.includes('@')) {
      toast.info('Procurando noivo com este e-mail...');
      response = await supplierContent.getBridesByBrides(
        order,
        'pontuar',
        null,
        search,
        pageNumber,
        resPerPage
      );
    } else {
      toast.info('Procurando noivo com este nome...');
      response = await supplierContent.getBridesByBrides(
        order,
        'pontuar',
        search,
        null,
        pageNumber,
        resPerPage
      );
    }

    if (response.ok && response.data) {
      changeState(response.data);
    }
    if (response.data.count === 0 || !response.ok) {
      setErrorOrEmpty(true);
    }
    setIsLoading(false);
  };

  const handleClear = async () => {
    setAvaliableBrides(null);
    setReportAvaliableData(null);
    setIsLoading(false);
  };

  const handleDisplay = displayType => {
    setIsLoading(true);
    setDisplay(displayType);
    setIsLoading(false);
  };

  return (
    <PageWrapper>
      <PageTitle>Pontuar Noivos</PageTitle>
      <StyledDiv ref={myRef}>
        <MaterialInput
          style={{ width: '100%' }}
          label="Nome ou email do noivo"
          onChange={event => setSearch(event.target.value)}
        />
      </StyledDiv>
      <StyledDiv>
        <StyledButton text="BUSCAR" click={handleSearch} />
        <StyledButton text="LIMPAR" click={handleClear} />
      </StyledDiv>
      <StyledDiv>
        {errorOrEmpty ? (
          <p>
            Noivo não encontrado. Verifique se já não está casado ou foi
            inserido por sua empresa. Noivos não podem ser pontuados caso tenham
            sido inseridos pelo mesmo fornecedor.
          </p>
        ) : (
          reportAvaliableData && (
            <>Resultados encontrados: {reportAvaliableData}</>
          )
        )}
      </StyledDiv>
      {avaliableBrides && !isLoading && reportAvaliableData ? (
        <>
          <p>
            Modo de exibição atual:{' '}
            <StyledSpan>{!cardDisplay ? 'Tabela' : 'Card'}</StyledSpan>{' '}
            <button onClick={() => handleDisplay(!cardDisplay)}>
              {!cardDisplay ? 'Trocar para Card' : 'Trocar para Tabela'}
            </button>
          </p>
          {cardDisplay ? (
            <StyledTabs
              content={tabsContent}
              components={components}
              onClick={activeTab => handleTabChange(activeTab)}
              activeTab={activeTab}
              hasActiveTab={true}
            />
          ) : (
            <StyledTabs
              content={tabsContent}
              components={tableComponents}
              onClick={activeTab => handleTabChange(activeTab)}
              activeTab={activeTab}
              hasActiveTab={true}
            />
          )}
        </>
      ) : (
        '  '
      )}
      {isLoading && <Loader />}
      {avaliableBrides &&
        avaliableBrides.length > 0 &&
        reportAvaliableData &&
        reportAvaliableData > 0 &&
        activeTab === 0 && (
          <Pagination2
            items={avaliableBrides}
            itemsTotal={reportAvaliableData}
            onChangePage={onPageChange}
            pageSize={resPerPage}
            scrollToRef={executeScroll}
            initialPage={initialPage}
          />
        )}
    </PageWrapper>
  );
};

export default SupplierPointBridesPage;
