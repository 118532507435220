import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Redirect } from '@reach/router';

import {
  SearchBar,
  Tabs,
  PrizesCard,
  Pagination,
  PrizesFiltersModal,
  Loader,
  Pagination2,
} from '../components';
import { useStateValue } from '../state';
import useFetch, { method } from '../hooks/useFetch';
import { urls } from '../services/urls';
import theme from '../styles/theme';
import { bride, tracking } from '../services/api';
import { PageTitle, PageWrapper } from '../components/pageComponents';
import { scrollToRef } from '../hooks';

const tabsContent = [
  {
    id: 0,
    title: 'TODOS',
  },
  {
    id: 1,
    title: 'FAVORITOS',
  },
  {
    id: 2,
    title: 'JÁ RESGATADOS',
  },
];

const PointsContainer = styled.div`
  width: 100%;
  background-color: ${theme.colors.text_heading};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;
`;

const PointsText = styled.p`
  color: ${theme.colors.white};
  font-size: ${theme.fontSizeMed}px;
`;

const PointsBold = styled.strong`
  color: ${theme.colors.primary};
`;

const AttentionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 1.2em;
  text-align: center;
  padding: 0.4em;
`;

const AttentionTitle = styled.h4`
  color: ${theme.colors.text_heading};
  align-self: flex-start;
`;

const AttentionText = styled.p`
  font-size: ${theme.fontSizeMed}px;
  color: ${theme.colors.grey3};
  align-self: flex-start;
  text-align: left;
  margin: 0;
`;

const CenteredText = styled.p`
  text-align: center;
`;

const BridePrizesPage = () => {
  const [searchFilter, setSearchFilter] = React.useState(null);
  const [segmentFilter, setSegmentFilter] = React.useState(null);
  const [supplierFilter, setSupplierFilter] = React.useState(null);
  const [valuesFilter, setValuesFilter] = React.useState(null);
  const [initialPage, setInitialPage] = React.useState(1);
  const [filter, setFilter] = React.useState(null);
  const [prizeData, setPrizeData] = useState(null);
  const [prizeTotal, setPrizeTotal] = useState(null);
  const [prizeFavorites, setPrizeFavorites] = useState(null);
  const [prizeRedeemed, setPrizeRedeemed] = useState(null);
  const [eventPoints, setEventPoints] = useState(null);
  const [prizesFilters, setPrizesFilters] = useState(false);
  const [searched, setSearched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const [
    {
      session: { date, userId, selectedEvent, selectedRegion },
    },
  ] = useStateValue();

  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);

  const resPerPage = 10;
  const fetchPrizes = async pageNumber => {
    setInitialPage(pageNumber);
    setIsLoading(true);

    if (!filter) {
      const response = await bride.getPrizes(
        selectedEvent,
        selectedRegion,
        pageNumber,
        resPerPage
      );

      if (response.ok && response.data) {
        setPrizeData(response.data.prizes);
        setPrizeTotal(response.data.count);
        setSearched(false);
        setIsLoading(false);
        return;
      }
    }

    if (filter === 'segment') {
      const response = await bride.getPrizesBySegment(
        segmentFilter,
        selectedEvent,
        selectedRegion,
        pageNumber,
        resPerPage
      );

      if (response.ok && response.data) {
        setPrizeData(response.data.prizes);
        setPrizeTotal(response.data.count);
        setPrizesFilters(false);
        setIsLoading(false);
        setSearched(true);
        return;
      }
    }

    if (filter === 'supplier') {
      const response = await bride.getPrizesBySupplier(
        supplierFilter,
        selectedEvent,
        selectedRegion,
        pageNumber,
        resPerPage
      );

      if (response.ok && response.data) {
        setPrizeData(response.data.prizes);
        setPrizeTotal(response.data.count);
        setPrizesFilters(false);
        setIsLoading(false);
        setSearched(true);
        return;
      }
    }

    if (filter === 'values') {
      const response = await bride.getPrizesByValues(
        valuesFilter.minValue,
        valuesFilter.maxValue,
        selectedEvent,
        selectedRegion,
        pageNumber,
        resPerPage
      );

      if (response.ok && response.data) {
        setPrizeData(response.data.prizes);
        setPrizeTotal(response.data.count);
        setPrizesFilters(false);
        setIsLoading(false);
        setSearched(true);
        return;
      }
    }

    if (filter === 'search') {
      const response = await bride.getPrizesByKeyword(
        searchFilter,
        selectedEvent,
        selectedRegion,
        pageNumber,
        resPerPage
      );

      if (response.ok && response.data) {
        setPrizeData(response.data.prizes);
        setPrizeTotal(response.data.count);
        setIsLoading(false);
        setSearched(true);
        return;
      }
    }
  };

  /*useFetch({
    url: urls.bridesContent.bridePrizes(selectedEvent, selectedRegion),
    method: method.GET,
    condition: () => !prizeData,
    callback: data => {
      if (data) {
        setPrizeData(data.prizes);
        setPrizeTotal(data.count);
      }
    },
  });*/

  useEffect(() => {
    const fetchBridePrizes = async () => {
      const res = await bride.getPrizes(selectedEvent, selectedRegion);

      if (res.ok && res.data) {
        setPrizeData(res.data.prizes);
        setPrizeTotal(res.data.count);
      }
    };
    fetchBridePrizes();
  }, [selectedEvent, selectedRegion, setPrizeData, setPrizeTotal]);

  useFetch({
    url: urls.bridesContent.favoritesPrizes(selectedEvent),
    method: method.GET,
    condition: () => !prizeFavorites,
    callback: data => data && setPrizeFavorites(data),
  });

  const { doUpdate } = useFetch({
    url: urls.bridesContent.redemeedPrizes(selectedEvent),
    method: method.GET,
    condition: () => !prizeRedeemed,
    callback: data => data && setPrizeRedeemed(data),
  });

  useFetch({
    url: urls.bridesContent.eventPoints(selectedEvent),
    method: method.GET,
    condition: () => !eventPoints,
    callback: ({ valor }) =>
      valor ? setEventPoints(valor) : 'no valor (setEventsPoints)',
  });

  const reloadRedeemed = () => {
    setPrizeRedeemed(null);
    doUpdate();
  };

  const removeFavorite = async cod => {
    const oldPrize = prizeData;
    const oldFavorites = prizeFavorites;
    const newPrize = prizeData.map(prize => {
      if (prize.codPrize === cod) {
        prize = { ...prize, isFavorited: false };
      }
      return prize;
    });
    const newPrizes = prizeFavorites.filter(prize => prize.codPrize !== cod);

    setPrizeData(newPrize);
    setPrizeFavorites(newPrizes);

    const response = await bride.removeFavorite(selectedEvent, 3, cod);
    if (!response.ok) {
      setPrizeData(oldPrize);
      setPrizeFavorites(oldFavorites);
    }
  };

  const addFavorite = async cod => {
    const oldPrize = prizeData;
    const oldFavorites = prizeFavorites;
    const newPrize = prizeData.map(prize => {
      if (prize.codPrize === cod) {
        prize = { ...prize, isFavorited: true };
      }
      return prize;
    });
    setPrizeData(newPrize);

    const newPrizeFav = newPrize.filter(prize => prize.codPrize === cod);
    const newFavorites = prizeFavorites.concat(newPrizeFav);
    setPrizeFavorites(newFavorites);
    setPrizeData(newPrize);

    const payload = {
      codEvent: selectedEvent,
      codRegion: selectedRegion,
      codPrize: cod,
    };

    const response = await bride.addFavorite(payload);
    if (!response.ok) {
      setPrizeFavorites(oldFavorites);
      setPrizeData(oldPrize);
    }
  };

  const renderItem = item => (
    <PrizesCard
      tab={activeTab}
      prizeData={item}
      removeFavorite={removeFavorite}
      addFavorite={addFavorite}
      reloadRedeemed={reloadRedeemed}
      eventPoints={eventPoints}
    />
  );

  const openModal = () => {
    setPrizesFilters(true);
  };

  const handleFilter = async (type, data) => {
    if (type === 1) {
      setIsLoading(true);
      setFilter('segment');
      setSegmentFilter(data);
      const response = await bride.getPrizesBySegment(
        data,
        selectedEvent,
        selectedRegion
      );

      if (response.ok && response.data) {
        setPrizeData(response.data.prizes);
        setPrizeTotal(response.data.count);
        setPrizesFilters(false);
        setIsLoading(false);
        setSearched(true);
        return;
      }
    }

    if (type === 2) {
      setIsLoading(true);
      setFilter('supplier');
      setSupplierFilter(data);
      const response = await bride.getPrizesBySupplier(
        data,
        selectedEvent,
        selectedRegion
      );

      if (response.ok && response.data) {
        setPrizeData(response.data.prizes);
        setPrizeTotal(response.data.count);
        setPrizesFilters(false);
        setIsLoading(false);
        setSearched(true);
        return;
      }
    }

    const { minValue, maxValue } = data;
    setIsLoading(true);
    setFilter('values');
    setValuesFilter(data);
    const response = await bride.getPrizesByValues(
      minValue,
      maxValue,
      selectedEvent,
      selectedRegion
    );

    if (response.ok && response.data) {
      setPrizeData(response.data.prizes);
      setPrizeTotal(response.data.count);
      setPrizesFilters(false);
      setIsLoading(false);
      setSearched(true);
      return;
    }
    setPrizesFilters(false);
  };

  const renderContent = data => {
    return data.length !== 0 ? (
      <Pagination
        itemsPerPage={10}
        data={data}
        renderItem={renderItem}
        showButtons={false}
      />
    ) : (
      <CenteredText>Nenhum prêmio disponivel</CenteredText>
    );
  };

  const handleDateActive = () => {
    const now = moment();
    const deadlineDate = now.subtract(30, 'days').format('YYYY-MM-DD');

    return deadlineDate <= date;
  };

  const handleSearch = async input => {
    setIsLoading(true);
    if (!input) {
      const response = await bride.bridePrizes(selectedEvent, selectedRegion);
      const response2 = await bride.favoritesPrizes(selectedEvent);
      const response3 = await bride.redemeedPrizes(selectedEvent);

      if (
        response &&
        response.data &&
        response2 &&
        response2.data &&
        response3 &&
        response.data
      ) {
        setPrizeData(response.data.prizes);
        setPrizeTotal(response.data.count);
        setPrizeFavorites(response2.data);
        setPrizeRedeemed(response3.data);
        setIsLoading(false);
        return;
      }
    }
    setFilter('keyword');
    setSearchFilter(input);
    setInitialPage(1);
    const response = await bride.getPrizesByKeyword(
      input,
      selectedEvent,
      selectedRegion
    );

    if (response.ok && response.data) {
      setPrizeData(response.data.prizes);
      setPrizeTotal(response.data.count);
      setIsLoading(false);
      setSearched(true);
    }

    await tracking.search({
      userId,
      eventId: selectedEvent,
      input,
      module: 'premio',
    });
  };

  const handleClear = async () => {
    setIsLoading(true);
    setInitialPage(1);
    setPrizeData(null);
    setPrizeTotal(null);

    const response = await bride.getPrizes(selectedEvent, selectedRegion);

    if (response.ok && response.data) {
      setPrizeData(response.data.prizes);
      setPrizeTotal(response.data.count);
      setSearched(false);
      setIsLoading(false);
      return;
    }
  };

  const onPageChange = pageNumber => {
    setIsLoading(true);
    fetchPrizes(pageNumber);
  };

  const components = [
    prizeData && renderContent(prizeData),
    prizeFavorites && renderContent(prizeFavorites),
    prizeRedeemed && renderContent(prizeRedeemed),
  ];

  if (!handleDateActive()) {
    return <Redirect to="/app/noivos/inicio" />;
  }

  return (
    <PageWrapper>
      <div ref={myRef}>
        <PageTitle>PRÊMIOS DISPONÍVEIS PARA RESGATE</PageTitle>
      </div>
      {prizeData &&
      prizeFavorites &&
      prizeRedeemed &&
      handleDateActive() === true &&
      !isLoading ? (
        <>
          <SearchBar
            filter
            searched={searched}
            onClickClear={handleClear}
            onClickFilter={openModal}
            onClickSearch={handleSearch}
            placeholder="Palavra-chave?"
            prizesPage
          />
          <PrizesFiltersModal
            opened={prizesFilters}
            onClickFilter={handleFilter}
            onCloseModal={() => setPrizesFilters(false)}
          />
          <AttentionContainer>
            <AttentionTitle>ATENÇÃO</AttentionTitle>
            <AttentionText>
              <b>Prazo 1 - </b>para solicitar os prêmios na plataforma: até 30
              dias após a data do casamento.
            </AttentionText>
            <AttentionText>
              <b>Prazo 2 - </b>para formalização da utilização do prêmio com o
              fornecedor: até 30 dias após a data da solicitação da plataforma.
              Após esse prazo a solicitação será cancelada e o prêmio voltará
              para o estoque <b>(sem reembolso dos pontos gastos) </b>e não
              poderá mais ser resgatado se o Prazo 1 estiver expirado ou outro
              cliente resgatar o mesmo.
            </AttentionText>
            <AttentionText>
              <b>Formalização do Resgate:</b> Nos detalhes do prêmio estão
              disponíveis os dados do fornecedor responsável pelo fornecimento;
              após resgatar o prêmio em questão, entre em contato com ele para
              agendar a data da retirada.
            </AttentionText>
            <AttentionText>
              <b>Disponibilidade:</b> os prêmios ficam disponíveis nesta tela
              somente enquanto possuem estoque, ficando indisponíveis caso o
              estoque acabe.
            </AttentionText>
          </AttentionContainer>
          <PointsContainer>
            <PointsText>
              Seu saldo: <PointsBold>{eventPoints}*</PointsBold>
              <br />
              <small>
                *Você ganha 1 ponto a cada 300 reais em seu contrato fechado
                dentro da rede, com o limite máximo de 30 pontos por contrato.
              </small>
            </PointsText>
          </PointsContainer>
          <Tabs
            content={tabsContent}
            components={components}
            onClick={tab => setActiveTab(tab)}
          />
          {activeTab === 0 &&
            prizeData &&
            prizeData.length > 0 &&
            prizeTotal &&
            prizeTotal > 0 && (
              <Pagination2
                items={prizeData}
                itemsTotal={prizeTotal}
                onChangePage={onPageChange}
                pageSize={resPerPage}
                scrollToRef={executeScroll}
                initialPage={initialPage}
              />
            )}
        </>
      ) : (
        <Loader />
      )}
    </PageWrapper>
  );
};

export default BridePrizesPage;
