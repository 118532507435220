import React, { useState } from 'react';
import styled from 'styled-components';

import { Tabs, Loader } from '../components';
import {
  PageTitle,
  PageWrapper,
  AttentionContainer,
  PointsContainer,
} from '../components/pageComponents';
import SupplierRewardCard from '../components/SupplierRewardCard';
import { supplier } from '../services/api';
import { useStateValue } from '../state';
import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import { hexToRgb } from '../utils/hexToRgb';
import Arabesco from '../images/arabescos.svg';

const tabsContent = [
  {
    id: 0,
    title: 'DISPONÍVEIS',
  },
  {
    id: 1,
    title: 'RESGATADOS',
  },
];

const StyledTabs = styled(Tabs)`
  width: 100%;
`;

const Card = styled.div`
  width: 90%;
  position: relative;
  min-height: 6rem;
  border: 1px solid ${theme.colors.grey1};
  border-radius: 20px;
  background-color: ${theme.colors.white};
  box-shadow: 0px 4px 8px 0 ${hexToRgb(theme.colors.grey2, 0.5)};
  padding-left: 0.5em;
  padding-right: 0.5em;
`;

const AssetContainer = styled.div`
  @media ${medias.tabletMin} {
    background-size: contain;
  }

  background-image: url(${Arabesco});
  background-size: cover;
  background-repeat-x: repeat;
  width: 100%;
  height: 2em;
  ${({ bottom }) =>
    bottom
      ? 'margin-top: 1em; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; '
      : 'margin-bottom: 1em;'}
`;

const SupplierRewardsPage = () => {
  const [allRewards, setAllRewards] = useState(null);
  const [selectedRewards, setSelectedRewards] = useState(null);
  const [activeTab, setTab] = useState(0);
  const [
    {
      session: { vbs },
    },
  ] = useStateValue();

  const getRewards = async () => {
    try {
      const { ok, data } = await supplier.getRewards();
      if (ok && data && Array.isArray(data)) {
        setAllRewards(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    getRewards();
  }, []);

  React.useEffect(() => {
    if (!allRewards) {
      return;
    }
    setSelectedRewards(
      allRewards.filter(r =>
        activeTab === 1 ? r.redeemed > 0 : r.redeemed <= 0
      )
    );
  }, [allRewards, activeTab]);

  return (
    <PageWrapper>
      <PageTitle>Recompensas disponíveis para resgate</PageTitle>
      <AttentionContainer>
        <h2>ATENÇÃO</h2>
        <p>Recompensas disponíveis apenas durante o contrato vigente.</p>
      </AttentionContainer>
      <PointsContainer>
        <p>
          Suas moedas: <b>{typeof vbs === 'number' && vbs}</b>
        </p>
      </PointsContainer>
      <StyledTabs content={tabsContent} onClick={id => setTab(id)} />
      {selectedRewards ? (
        selectedRewards.length > 0 ? (
          <Card>
            <AssetContainer />
            {selectedRewards.map(r => (
              <SupplierRewardCard key={r.name} balance={vbs} {...r} />
            ))}
          </Card>
        ) : (
          <p style={{ textAlign: 'center', marginTop: '2rem' }}>
            Nenhuma recompensa encontrada!
          </p>
        )
      ) : (
        <Loader />
      )}
    </PageWrapper>
  );
};

export default SupplierRewardsPage;
