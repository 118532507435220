import React from 'react';
import { PageTitle, PageWrapper } from '../components/pageComponents';

import { BrideProfileForm } from '../containers';

const BrideReferPage = () => {
  return (
    <PageWrapper>
      <PageTitle>PERFIL</PageTitle>
      <BrideProfileForm />
    </PageWrapper>
  );
};

export default BrideReferPage;
