import React, { useState } from 'react';
import { useFormState } from 'react-use-form-state';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import {
  MaterialInput,
  MaterialSelect,
  MaterialMenuItem,
  Button,
  MoneyMask,
  MaterialDatePicker,
  Loader,
} from '../../components';
import Arabesco from '../../images/arabescos.svg';
import { medias } from '../../utils/mediaQueries';
import { theme } from '../../styles/theme';
import { hexToRgb } from '../../utils/hexToRgb';
//import { investmentValue } from '../../utils/dropdownValues';
import useFetch, { method } from '../../hooks/useFetch';
import { urls } from '../../services/urls';
import { siteContent, userData, bride } from '../../services/api';
import { useStateValue } from '../../state';

import moment from 'moment';
import dateUndefined from '../../utils/undefinedDate';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 2em;

  background-color: ${theme.colors.white};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 3px 10px 0px ${hexToRgb(theme.colors.grey2, 0.5)};
`;

const AssetContainer = styled.div`
  @media ${medias.tabletMin} {
    background-size: contain;
  }

  background-image: url(${Arabesco});
  background-size: cover;
  background-repeat-x: repeat;
  width: 100%;
  height: 2em;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  ${({ bottom }) => bottom && `margin-top: 1em;`}
`;

const ButtonsContainer = styled.section`
  display: flex;
  justify-content: space-around;
  margin-top: 0.5em;
  width: 100%;
`;

const StyledForm = styled.form`
  width: 100%;
  padding: 0 1em;
`;

const StyledLabel = styled.label`
  color: ${theme.colors.grey3};
  font-size: 0.8em;
  margin: 0;
  text-align: left;

  @media (max-width: 756px) {
    font-size: 0.9em !important;
  }
`;

const StyledButton = styled(props => <Button {...props} />)`
  width: 40%;
  background-color: ${theme.colors.text_heading};
  border-color: ${theme.colors.text_heading};
  color: white;
  font-size: ${theme.fontSizeSm}px;
  margin-top: 2em;
`;

const StyledFieldset = styled.div`
  width: 100%;
  border: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin-top: 0.25em;
`;

const Checkbox = styled.input`
  margin-right: 0.5em;
`;

const StyledSpan = styled.p`
  margin: 0;
  font-size: ${theme.fontSizeSm}px;
  color: ${theme.colors.grey3};
`;

const StyledDatePicker = styled(props => <MaterialDatePicker {...props} />)`
  margin: 5px 0 5px 0;
`;

const MarriageForm = ({
  nextStep,
  stepBack,
  states,
  brideWelcomeForm,
  dispatch,
}) => {
  const [availableCitiesCerimony, setAvailableCitiesCerimony] = useState(null);
  const [availableCitiesReception, setCitiesReception] = useState(null);
  const [loading, setLoading] = useState(false);
  const [contact, setContact] = useState(false);
  const [date, setDate] = useState(null);
  const [allowChangeDate, setAllow] = useState(true);
  const [undefinedDate, setDateUndefined] = useState(false);
  const [
    {
      session: { selectedEvent },
    },
  ] = useStateValue();

  const [formState, { text, select, label }] = useFormState(null, {
    withIds: true,
    onChange(e) {
      const { name, value } = e.target;
      dispatch({
        type: 'changeBrideWelcomeForm',
        field: name,
        newText: value,
      });

      if (name === 'ufCerimony') {
        fetchCities(value, 1);
      } else if (name === 'ufReception') {
        fetchCities(value, 2);
      }
    },
  });

  useFetch({
    url: urls.userdata.weddingInfo,
    method: method.GET,
    condition: () => !date,
    callback: data => {
      const {
        weddingDate,
        secondaryBrideEmail,
        secondaryBrideName,
        eventName,
        cerimonyLocation,
        ufCerimony,
        cityCerimony,
        receptionLocation,
        ufReception,
        cityReception,
        investment,
        invited,
      } = data;
      if (weddingDate) {
        setAllow(weddingDate === dateUndefined ? true : false);
        setDate(weddingDate === dateUndefined ? null : weddingDate);
        setDateUndefined(weddingDate === dateUndefined ? true : false);
      } else {
        setAllow(true);
        setDate(null);
        setDateUndefined(true);
      }
      const userData = {
        secondaryBrideEmail,
        secondaryBrideName,
        eventName,
        cerimonyLocation,
        ufCerimony,
        cityCerimony,
        receptionLocation,
        ufReception,
        cityReception,
        investment,
        invited,
      };
      dispatch({
        type: 'setBrideDataForm',
        newForm: userData,
      });

      Object.keys(userData).forEach(key =>
        formState.setField(key, userData[key])
      );
      fetchCities(ufCerimony, 1);
      fetchCities(ufReception, 2);
    },
  });

  const fetchCities = async (codUf, type) => {
    const response = await siteContent.cities(codUf);
    if (response.ok && response.data) {
      type === 1
        ? setAvailableCitiesCerimony(response.data)
        : setCitiesReception(response.data);
    }
  };

  const onSubmitForm = async () => {
    setLoading(true);
    const { secondaryBrideEmail, secondaryBrideName, investment, invited } = brideWelcomeForm;
    const payload = formState.values;
    delete payload.ufReception;
    delete payload.ufCerimony;
    delete payload.secondaryBrideName;
    delete payload.secondaryBrideEmail;
    payload.investment = investment ? investment : 0;
    payload.invited = invited ? invited : 0;

    const responseRequired = await userData.setMarriageInfo({
      ...payload,
      weddingDate: undefinedDate ? dateUndefined : date,
    });

    if (secondaryBrideEmail && secondaryBrideName) {
      const payload = {
        name: secondaryBrideName,
        email: secondaryBrideEmail,
        codEvent: selectedEvent,
        userType: 1,
      };

      const response = await bride.addUserToEvent(payload);
      setLoading(false);
      if (responseRequired.ok && response.ok) {
        toast.info('Dados atualizados com sucesso!');
        return nextStep();
      }

      if (response.status === 412) {
        toast.error('Usuário já está cadastrado como noivo no sistema!');
        return;
      }

      toast.error('Houve um erro na submissão.');
      return;
    }

    if (responseRequired.ok) {
      toast.info('Dados atualizados com sucesso!');
      return nextStep();
    }
    toast.error('Houve um erro na submissão.');
  };

  /*const handleInvestment = () => {
    return investmentValue.map(item => {
      return (
        <MaterialMenuItem value={item} key={item}>
          {item}
        </MaterialMenuItem>
      );
    });
  };*/

  const onClickCheckbox = async () => {
    setContact(!contact);
    const { ufCerimony, cityCerimony, cerimonyLocation } = formState.values;

    await fetchCities(ufCerimony, 2);

    formState.setField('ufReception', !contact ? ufCerimony : '');
    dispatch({
      type: 'changeBrideWelcomeForm',
      field: 'ufReception',
      newText: ufCerimony,
    });

    formState.setField('cityReception', !contact ? cityCerimony : '');
    dispatch({
      type: 'changeBrideWelcomeForm',
      field: 'cityReception',
      newText: cityCerimony,
    });

    formState.setField('receptionLocation', !contact ? cerimonyLocation : '');
    dispatch({
      type: 'changeBrideWelcomeForm',
      field: 'receptionLocation',
      newText: cerimonyLocation,
    });
  };

  const handleNextClick = () => {
    const {
      cerimonyLocation,
      ufCerimony,
      cityCerimony,
      receptionLocation,
      ufReception,
      cityReception,
      eventName,
      invited,
    } = brideWelcomeForm;
    eventName === null ||
    cerimonyLocation === null ||
    ufCerimony === null ||
    cityCerimony === null ||
    receptionLocation === null ||
    ufReception === null ||
    cityReception === null ||
    (invited && typeof invited !== 'number')
      ? toast.error('Preencha todos os campos obrigatórios!')
      : onSubmitForm();
  };

  return (
    <Card className="bootstrap-wrapper">
      <AssetContainer />
      <StyledForm onSubmit={() => console.log(formState)}>
        <div className="row">
          <div className="col-md-6">
            <StyledDatePicker
              label="Data do casamento"
              format={'DD/MM/YYYY'}
              value={date === dateUndefined ? null : date}
              disabled={undefinedDate || !allowChangeDate}
              onChange={date => setDate(moment(date).format('YYYY-MM-DD'))}
            />
          </div>
        </div>
        <StyledFieldset>
          <Checkbox
            disabled={!allowChangeDate}
            type="checkbox"
            name="hasDate"
            value="Não definida"
            onChange={() => setDateUndefined(!undefinedDate)}
            checked={undefinedDate}
          />
          <StyledSpan>Não definida</StyledSpan>
        </StyledFieldset>
        <div className="row">
          <div className="col-md-6">
            <MaterialInput
              {...text('secondaryBrideEmail')}
              style={{
                color: theme.colors.grey3,
              }}
              label="Email do seu noivo(a)"
            />
          </div>

          <div className="col-md-6">
            <MaterialInput
              {...text('secondaryBrideName')}
              style={{
                color: theme.colors.grey3,
              }}
              label="Nome do seu noivo(a)"
            />
          </div>
        </div>
        {/* <InputsSection>
          <MaterialInput
            {...text('secondaryBrideEmail')}
            style={{
              color: theme.colors.grey3,
            }}
            label="Email do seu noivo(a)"
          />
          <MaterialInput
            {...text('secondaryBrideName')}
            style={{
              color: theme.colors.grey3,
            }}
            label="Nome do seu noivo(a)"
          />
        </InputsSection> */}
        <MaterialInput
          {...text('eventName')}
          style={{ width: '100%', color: theme.colors.grey3 }}
          required
          label="Nome do evento"
        />
        <MaterialInput
          {...text('cerimonyLocation')}
          style={{ width: '100%', color: theme.colors.grey3 }}
          required
          label="Local da cerimônia"
        />
        <div className="row">
          <div className="col-md-6">
            <StyledLabel {...label('ufCerimony')}>
              Estado da cerimônia *
            </StyledLabel>
            <MaterialSelect
              {...select({
                name: 'ufCerimony',
                validate: () => {},
              })}
            >
              {states
                ? states.map(({ name, cod }) => (
                    <MaterialMenuItem key={cod} value={cod}>
                      {name}
                    </MaterialMenuItem>
                  ))
                : null}
            </MaterialSelect>
          </div>

          <div className="col-md-6">
            <StyledLabel {...label('cityCerimony')}>
              Cidade da cerimônia *
            </StyledLabel>
            <MaterialSelect
              disabled={!brideWelcomeForm.ufCerimony}
              {...select({
                name: 'cityCerimony',
                validate: () => {},
              })}
            >
              {availableCitiesCerimony
                ? availableCitiesCerimony.map(
                    ({ nomeCidade, codCidadeIBGE }) => (
                      <MaterialMenuItem
                        key={codCidadeIBGE}
                        value={codCidadeIBGE}
                      >
                        {nomeCidade}
                      </MaterialMenuItem>
                    )
                  )
                : null}
            </MaterialSelect>
          </div>
        </div>
        {/* <InputsSection>
          <SelectorContainer>
            <FormControl style={{ width: '100%' }}>
              <StyledLabel {...label('ufCerimony')}>
                Estado da cerimônia *
              </StyledLabel>
              <MaterialSelect
                {...select({
                  name: 'ufCerimony',
                  validate: () => {},
                })}
              >
                {states
                  ? states.map(({ name, cod }) => (
                      <MaterialMenuItem key={cod} value={cod}>
                        {name}
                      </MaterialMenuItem>
                    ))
                  : null}
              </MaterialSelect>
            </FormControl>
          </SelectorContainer>
          <SelectorContainer>
            <FormControl style={{ width: '100%' }}>
              <StyledLabel {...label('cityCerimony')}>
                Cidade da cerimônia *
              </StyledLabel>
              <MaterialSelect
                {...select({
                  name: 'cityCerimony',
                  validate: () => {},
                })}
              >
                {availableCitiesCerimony
                  ? availableCitiesCerimony.map(
                      ({ nomeCidade, codCidadeIBGE }) => (
                        <MaterialMenuItem
                          key={codCidadeIBGE}
                          value={codCidadeIBGE}
                        >
                          {nomeCidade}
                        </MaterialMenuItem>
                      )
                    )
                  : null}
              </MaterialSelect>
            </FormControl>
          </SelectorContainer>
        </InputsSection> */}
        <StyledFieldset>
          <Checkbox
            type="checkbox"
            name="hasDate"
            value="Não definida"
            onChange={onClickCheckbox}
            checked={contact}
          />
          <StyledSpan>Recepção no mesmo local da Cerimonia?</StyledSpan>
        </StyledFieldset>
        <MaterialInput
          {...text('receptionLocation')}
          style={{ width: '100%', color: theme.colors.grey3 }}
          required
          label="Local da recepção"
        />

        <div className="row">
          <div className="col-md-6">
            <StyledLabel {...label('ufReception')}>
              Estado da recepção *
            </StyledLabel>
            <MaterialSelect
              {...select({
                name: 'ufReception',
                validate: () => {},
              })}
            >
              {states
                ? states.map(({ name, cod }) => (
                    <MaterialMenuItem key={cod} value={cod}>
                      {name}
                    </MaterialMenuItem>
                  ))
                : null}
            </MaterialSelect>
          </div>

          <div className="col-md-6">
            <StyledLabel {...label('cityReception')}>
              Cidade da recepção *
            </StyledLabel>
            <MaterialSelect
              disabled={!brideWelcomeForm.ufReception}
              {...select({
                name: 'cityReception',
                validate: () => {},
              })}
            >
              {availableCitiesReception
                ? availableCitiesReception.map(
                    ({ nomeCidade, codCidadeIBGE }) => (
                      <MaterialMenuItem
                        key={codCidadeIBGE}
                        value={codCidadeIBGE}
                      >
                        {nomeCidade}
                      </MaterialMenuItem>
                    )
                  )
                : null}
            </MaterialSelect>
          </div>
        </div>
        {/* <InputsSection>
          <SelectorContainer>
            <FormControl style={{ width: '100%' }}>
              <StyledLabel {...label('ufReception')}>
                Estado da recepção *
              </StyledLabel>
              <MaterialSelect
                {...select({
                  name: 'ufReception',
                  validate: () => {},
                })}
              >
                {states
                  ? states.map(({ name, cod }) => (
                      <MaterialMenuItem key={cod} value={cod}>
                        {name}
                      </MaterialMenuItem>
                    ))
                  : null}
              </MaterialSelect>
            </FormControl>
          </SelectorContainer>
          <SelectorContainer>
            <FormControl style={{ width: '100%' }}>
              <StyledLabel {...label('cityReception')}>
                Cidade da recepção *
              </StyledLabel>
              <MaterialSelect
                {...select({
                  name: 'cityReception',
                  validate: () => {},
                })}
              >
                {availableCitiesReception
                  ? availableCitiesReception.map(
                      ({ nomeCidade, codCidadeIBGE }) => (
                        <MaterialMenuItem
                          key={codCidadeIBGE}
                          value={codCidadeIBGE}
                        >
                          {nomeCidade}
                        </MaterialMenuItem>
                      )
                    )
                  : null}
              </MaterialSelect>
            </FormControl>
          </SelectorContainer>
        </InputsSection> */}
        <div className="row">
          <div className="col-md-6">
            <MaterialInput
              onChange={event =>
                dispatch({
                  type: 'changeBrideWelcomeForm',
                  field: 'investment',
                  newText: Number(event.target.value),
                })
              }
              style={{ width: '100%', color: theme.colors.grey3 }}
              required
              label="Investimento estimado *"
              InputProps={{
                inputComponent: MoneyMask,
              }}
              value={brideWelcomeForm.investment}
            />
          </div>
          <div className="col-md-6">
            <MaterialInput
              onChange={event =>
                dispatch({
                  type: 'changeBrideWelcomeForm',
                  field: 'invited',
                  newText: Number(event.target.value),
                })
              }
              style={{ width: '100%', color: theme.colors.grey3 }}
              value={brideWelcomeForm.invited}
              required
              type="number"
              label="Convidados *"
            />
            {/*          <StyledLabel {...label('invited')}>
              Convidados *</StyledLabel><MaterialSelect
                {...select({
                  name: 'invited',
                  validate: () => {},
                })}
              >
                {handleInvited()}
              </MaterialSelect>*/}
          </div>
        </div>
        {/* <InputsSection>
          <SelectorContainer>
            <FormControl style={{ width: '100%' }}>
              <StyledLabel {...label('investment')}>
                Investimento estimado *
              </StyledLabel>
              <MaterialSelect
                {...select({
                  name: 'investment',
                  validate: () => {},
                })}
              >
                {handleInvestment()}
              </MaterialSelect>
            </FormControl>
          </SelectorContainer>
          <SelectorContainer>
            <FormControl style={{ width: '100%' }}>
              <StyledLabel {...label('invited')}>Convidados *</StyledLabel>
              <MaterialSelect
                {...select({
                  name: 'invited',
                  validate: () => {},
                })}
              >
                {handleInvited()}
              </MaterialSelect>
            </FormControl>
          </SelectorContainer>
        </InputsSection> */}
        {loading && <Loader />}
        <ButtonsContainer>
          <StyledButton text="VOLTAR" click={stepBack} />
          <StyledButton text="AVANÇAR" click={handleNextClick} />
        </ButtonsContainer>
      </StyledForm>
      <AssetContainer bottom />
    </Card>
  );
};

export default MarriageForm;
