import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import {
  SupplierOpportunityCard,
  Tabs,
  Loader,
  OpportunitiesReports,
} from '../components';
import useFetch, { method } from '../hooks/useFetch';
import { urls } from '../services/urls';
import { PageWrapper, PageTitle } from '../components/pageComponents';
import { supplier } from '../services/api';

const tabsContent = [
  {
    id: 0,
    title: 'ORÇAMENTOS',
  },
  {
    id: 1,
    title: 'CONTACTADOS',
  },
];

const StyledTabs = styled(Tabs)`
  width: 100%;
`;

const SupplierOpportunitiesPage = () => {
  const [cardData, setCardData] = useState(null);

  const { doUpdate: updateCardData } = useFetch({
    url: urls.supplierContent.getOpportunitiesBrides(),
    method: method.GET,
    condition: () => !cardData,
    callback: data => setCardData(data),
  });

  useEffect(() => {
    const fetchData = async () => {
      const response = await supplier.getOpportunitiesBrides();

      if (response.ok && response.data) {
        setCardData(response.data);
      }
    };

    fetchData();
  }, []);

  const refreshData = () => {
    setCardData(null);
    updateCardData();
  };

  let reportData = {
    contacted: 0,
    notContacted: 0,
  };

  if (cardData) {
    reportData = {
      ...reportData,
      notContacted: cardData[0].length,
      contacted: cardData[1].length,
    }
  }

  const components = cardData && [
    <SupplierOpportunityCard
      key={0}
      selected={0}
      data={cardData[0]}
      refreshData={refreshData}
    />,
    <SupplierOpportunityCard
      key={1}
      selected={1}
      data={cardData[1]}
      refreshData={refreshData}
    />,
  ];

  return (
    <PageWrapper>
      <PageTitle>ORÇAMENTOS</PageTitle>
      {cardData && reportData ? (
        <>
          <StyledTabs
            content={tabsContent}
            components={components}
            lineWidth={'100%'}
            onClick={() => {}}
          />
          <OpportunitiesReports data={reportData} />
        </>
      ) : (
        <Loader />
      )}
    </PageWrapper>
  );
};

export default SupplierOpportunitiesPage;
