import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { Redirect } from '@reach/router';

import {
  SmallSupplierCard,
  JoinUs,
  SearchBar,
  SegmentList,
  BudgetModal,
} from '../components';
//import { navigate } from 'gatsby';
import { useStateValue } from '../state';
import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import { siteContent, tracking } from '../services/api';
import useFetch, { method } from '../hooks/useFetch';
import { urls } from '../services/urls';
import { bride } from '../services/api';
import Loader from '../components/Loader';
import { PageTitle, PageWrapper } from '../components/pageComponents';
import { scrollToRef } from '../hooks';
import { isBrowser } from '../services/checkSession';
//import { handleSession } from '../components/Header';
//import moment from 'moment';
import { toast } from 'react-toastify';
import checkSession from '../services/checkSession';

const StyledContainer = styled.div`
  display: flex;
  width: 90%;
  align-items: center;
  justify-content: center;
`;

const SearchResult = styled.div`
  width: 100%;
  background-color: ${theme.colors.text_heading};
  font-size: ${theme.fontSizeMed}px;
  @media ${medias.phoneMax} {
    font-size: ${theme.fontSizeSm}px;
  }
  padding: 2% 5%;
  color: ${theme.colors.white};
  font-weight: 300;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const YellowText = styled.span`
  color: ${theme.colors.primary};
  font-weight: bold;
  margin-right: 0.5em;
`;

const BlackTitle = styled.h5`
  color: ${theme.colors.text_heading};
  font-size: ${theme.fontSizeMed}px;
  text-align: center;
  margin: 1.5em;
`;

const NormalTitle = styled(BlackTitle)`
  font-weight: normal;
`;

const Text = styled.span`
  margin-top: 2px;
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSearchbar = styled(props => <SearchBar {...props} />)`
  padding: 0 6em;
  background-color: ${theme.colors.text_heading};
  @media ${medias.laptopMax} {
    padding: 0;
  }
  width: 100%;
  align-self: center;
  justify-content: space-between;
`;

const CardsContainer = styled.div`
  @media ${medias.phoneMax} {
    grid-template-columns: repeat(1, 1fr);
    width: 80%;
  }

  display: grid;
  margin-top: 20px;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 10px;
`;

export const SuppliersPage = ({ app, location: { state }, pageContext }) => {
  const [searched, setSearched] = useState(false);
  const [inputMessage, setInputMessage] = useState(false);
  const [suppliers, setSuppliers] = useState(null);
  const [searchString, setSearchString] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [stateInput, setStateInput] = useState('');
  const [budgetModal, setBudgetModal] = useState(false);
  const [budgetModalData, setBudgetModalData] = useState(null);

  useEffect(() => {
    state?.input &&
      !state.isSegment &&
      onSearchSuppliers(state.input, null, null, null);
    // eslint-disable-next-line
  }, [state]);

  const [
    {
      suppliers: { supplierSegments },
      segments: { segments },
      session: {
        userId,
        eventFinished,
        selectedEvent,
        selectedRegion,
        siteSelectedRegion,
        //stage
      },
    },
    dispatch,
  ] = useStateValue();
  const _siteSelectedRegion = siteSelectedRegion || 1;

  const refResults = useRef(null);
  const refPrincipalDiv = useRef(null);
  const executeScrollToResults = () => scrollToRef(refResults);
  const executeScrollToPrincipalDiv = () => scrollToRef(refPrincipalDiv);

  useFetch({
    url: urls.siteContent.supplierSegments,
    method: method.GET,
    condition: () => !supplierSegments,
    callback: data =>
      dispatch({
        type: 'setSupplierSegments',
        newSupplierSegments: data,
      }),
  });

  useFetch({
    url: urls.siteContent.segments,
    method: method.GET,
    condition: () => !segments,
    callback: data =>
      dispatch({
        type: 'setSegments',
        newSegments: data,
      }),
  });

  /*const fetchEventRegions = async codEvento => {
    const response = await bride.eventRegions(codEvento);
    if (response.ok && response.data) {
      dispatch({
        type: 'setEventRegions',
        newEventRegions: response.data,
      });
    }
  };*/

  /*const getSessionData = async () => {
    const response = await bride.getSessionData(userId);
    if (response.ok && response.data) {
      const {
        selectedEvent,
        selectedRegion,
        inactive,
        eventType,
        date,
        points,
        firstLogin,
      } = response.data;

      if (response.status === 401) {
        toast.info('A sua sessão expirou. Por favor faça login novamente');
          dispatch({
            type: 'logout',
          });
          dispatch({
            type: 'clearEvents',
          });
          dispatch({
            type: 'clearEventRegions',
          });
          sessionStorage.removeItem('siteInput')
          sessionStorage.removeItem('siteSegmentId')
          return navigate('/');
      }
      await fetchEventRegions(selectedEvent);

      const stage = handleSession(date);

      dispatch({
        type: 'updateSessionForm',
        eventType,
        date,
        eventFinished: date < moment().format('YYYY-MM-DD'),
        eventInactive: inactive === 1,
        points,
        firstLogin,
        selectedEvent,
        selectedRegion,
        stage,
      });
    }
    return;
  };*/

  useEffect(() => {
    if (userId) {
      checkSession(dispatch, true);
    }
  }, [userId, dispatch]);

  useEffect(() => {
    /*if (userId && !selectedEvent && !stage) {
      getSessionData();
      checkSession(dispatch, true);
      fetchEventRegions(selectedEvent);
    }*/

    let input;
    if (state) {
      //console.dir(state);
      input = state.input;
      if (state.isSegment) {
        setSelectedValue(state.segmentName);
        return onSearchSuppliers(input, null, true, state.segmentName);
      }
    }
    if (pageContext == null) {
      return;
    }
    if (pageContext && pageContext.segment) {
      const segment = pageContext.segment;
      onSearchSuppliers(segment);
      const decodedSegmentName = segment.replace(/-/g, ' ');
      //input = decodedSegmentName;
      setStateInput(decodedSegmentName);
      setSearchString(decodedSegmentName);
    }

    // eslint-disable-next-line
  }, [state, userId, selectedEvent]);

  const isLoggedIn = isBrowser() && !!localStorage.getItem('token');

  const addFavorite = async cod => {
    const oldState = suppliers;
    const newSup = suppliers.map(sup => {
      if (sup.codFornecedor === cod) {
        sup = { ...sup, isFavorited: true };
      }
      return sup;
    });
    setSuppliers(newSup);
    const payload = {
      codEvent: selectedEvent,
      codRegion: selectedRegion,
      codSupplier: cod,
    };
    const response = await bride.addFavorite(payload);
    if (!response.ok) {
      setSuppliers(oldState);
    }
  };

  const removeFavorite = async cod => {
    const oldState = suppliers;
    const newSup = suppliers.map(sup => {
      if (sup.codFornecedor === cod) {
        sup = { ...sup, isFavorited: false };
      }
      return sup;
    });
    setSuppliers(newSup);
    const response = await bride.removeFavorite(selectedEvent, 1, cod);
    if (!response.ok) {
      setSuppliers(oldState);
    }
  };

  const onFilterBySegment = async (segment, segmentName) => {
    setStateInput('');
    setIsLoading(true);
    executeScrollToPrincipalDiv();
    let _filteredSuppliers;

    tracking.segment({
      userId,
      eventId: selectedEvent,
      segmentId: segment,
    });

    let response;
    if (selectedRegion) {
      response = await siteContent.filteredSuppliersSegment(
        segment,
        selectedRegion
      );
    } else {
      response = await siteContent.filteredSuppliersSegment(
        segment,
        _siteSelectedRegion
      );
    }

    if (response.ok && response.data) {
      _filteredSuppliers = response.data;
      setIsLoading(false);
      executeScrollToResults();
    }

    setSuppliers(_filteredSuppliers);
    setSearchString('');
    setSelectedValue(segmentName);
    setSearched(true);
    dispatch({
      type: 'siteSearchSuppliers',
      siteInput: segment,
      siteInputIsSegment: true,
    });

    // window.scrollBy({
    //   top: window.innerHeight,
    //   behavior: 'smooth',
    // });
  };

  const onSearchSuppliers = async (
    input,
    dropdown,
    siteInputIsSegment,
    segmentName
  ) => {
    //sessionStorage.removeItem('siteInput');
    if (!input && !siteInputIsSegment) {
      return setInputMessage(true);
    }

    setIsLoading(true);
    setInputMessage(false);
    let _filteredSuppliers;
    dispatch({
      type: 'siteSearchSuppliers',
      siteInput: input,
      siteInputIsSegment,
    });
    let response;
    if (siteInputIsSegment) {
      setSelectedValue(segmentName);
      if (selectedRegion) {
        response = await siteContent.filteredSuppliersSegment(
          input,
          selectedRegion
        );
      } else {
        response = await siteContent.filteredSuppliersSegment(
          input,
          _siteSelectedRegion
        );
      }
    } else if (input) {
      setSearchString(input);
      if (selectedRegion) {
        response = await siteContent.filteredSuppliersSearch(
          input,
          selectedRegion
        );
      } else {
        response = await siteContent.filteredSuppliersSearch(
          input,
          _siteSelectedRegion
        );
      }
    } else {
      setIsLoading(false);
      dispatch({
        type: 'siteSearchSuppliers',
        siteInput: null,
      });
      return toast.error('Erro ao realizar pesquisa!');
    }

    if (response && response.ok && response.data) {
      setIsLoading(false);
      _filteredSuppliers = response.data;
    }

    setSuppliers(_filteredSuppliers);
    setSearched(true);

    /*if (input && location.pathname === '/fornecedores') {
      sessionStorage.setItem('siteInput', input);
    }
    */

    await tracking.search({
      userId,
      eventId: selectedEvent,
      input: siteInputIsSegment ? `segmento-${input}` : input,
      module: 'fornecedor',
    });
  };

  const handleSegments = () => {
    let initialSegments = segments
      .filter(segment => segment.faseSegmento === 1)
      .sort((a, b) => a.ordem - b.ordem);

    let mediumSegments = segments
      .filter(segment => segment.faseSegmento === 2)
      .sort((a, b) => a.ordem - b.ordem);

    let finalSegments = segments
      .filter(segment => segment.faseSegmento === 3)
      .sort((a, b) => a.ordem - b.ordem);

    return (
      <>
        <SegmentList
          title="Fase Inicial"
          items={initialSegments}
          filter
          onClickFilter={onFilterBySegment}
        />
        <SegmentList
          title="Fase Intermediária"
          _filteredSuppliers
          items={mediumSegments}
          filter
          onClickFilter={onFilterBySegment}
        />
        <SegmentList
          title="Fase Final"
          items={finalSegments}
          filter
          onClickFilter={onFilterBySegment}
        />
      </>
    );
  };

  const handleBudgetModal = data => {
    setBudgetModalData(data);
    setBudgetModal(true);
  };

  const handleSupplierCards = () => {
    const supplierData = suppliers.map(supplier => {
      const { codFornecedor } = supplier;
      const _supplierSegments = supplierSegments.filter(
        ({ codFornecedor: id }) => id === codFornecedor
      );

      const _segments = _supplierSegments.map(({ codSegmento }) => {
        const supplierSegment = segments.find(
          ({ codSegmento: id }) => id === codSegmento
        );

        if (supplierSegment) {
          return supplierSegment;
        }
      });

      const filteredSegments = _segments.filter(item => Boolean(item));

      const {
        nomeDivulgacao,
        regiaoInteresse: { Regiao },
        isFavorited,
        linkLogo,
        slugUrl,
      } = supplier;

      return {
        nomeDivulgacao,
        codFornecedor,
        Regiao,
        segmentos: filteredSegments,
        isFavorited,
        linkLogo,
        slugUrl,
      };
    });

    return supplierData.map(supplier => (
      <SmallSupplierCard
        key={supplier.codFornecedor}
        supplierData={supplier}
        isLoggedIn={isLoggedIn}
        app={app}
        addFavorite={addFavorite}
        removeFavorite={removeFavorite}
        onClickRequest={handleBudgetModal}
      />
    ));
  };

  const handleClear = async () => {
    setSuppliers(null);
    setSearched(false);
    setIsLoading(false);
  };

  const decodeCharacter = string => {
    if (string) {
      const input = string.replace('%26', '&');
      return input;
    }
    return string;
  };

  const handleInputString = () => {
    if (state) {
      decodeCharacter(stateInput);
    } else if (searchString && searchString.length > 0) {
      return searchString;
    }
  };

  if (eventFinished) {
    return <Redirect to="/app/noivos/inicio" />;
  }

  return (
    <>
      <PageWrapper>
        <PageTitle ref={refPrincipalDiv}>FORNECEDORES HOMOLOGADOS</PageTitle>
        {segments &&
        supplierSegments &&
        !!eventFinished === false &&
        !isLoading ? (
          <>
            <StyledSearchbar
              searchFromHome={handleInputString}
              droppedFromHome={state && state.dropdown}
              searched={searched}
              onClickClear={handleClear}
              onClickSearch={onSearchSuppliers}
              placeholder="O que você procura?"
              segmentsDropdown={true}
              noRegion
              home
            />
            {inputMessage && (
              <BlackTitle>
                Digite um fornecedor ou segmento na busca acima ou selecione um
                segmento na lista abaixo
              </BlackTitle>
            )}
            {searched && (
              <>
                {suppliers.length === 0 && stateInput !== 'Todos' ? (
                  <BlackTitle ref={refResults}>
                    Não foram encontrados fornecedores para esta pesquisa
                  </BlackTitle>
                ) : (
                  <SearchResult ref={refResults}>
                    <TextContainer>
                      <YellowText>Resultado da busca:</YellowText>
                      <Text>
                        {selectedValue && decodeCharacter(selectedValue)}
                        {searchString
                          ? selectedValue
                            ? ` / ${decodeCharacter(searchString)}`
                            : `${decodeCharacter(searchString)}`
                          : ''}
                      </Text>
                    </TextContainer>
                  </SearchResult>
                )}
                <CardsContainer>{handleSupplierCards()}</CardsContainer>
              </>
            )}
            <NormalTitle>
              Dividimos o planejamento do seu casamento por <b>FASES</b> e{' '}
              <b>SEGMENTOS</b>
            </NormalTitle>
            {handleSegments()}
            {!isLoggedIn && (
              <StyledContainer>
                <JoinUs />
              </StyledContainer>
            )}
          </>
        ) : (
          <Loader />
        )}
        {budgetModalData && (
          <BudgetModal
            closeModal={() => setBudgetModal(false)}
            opened={budgetModal}
            supplierData={budgetModalData}
            selectedEvent={selectedEvent}
          />
        )}
      </PageWrapper>
    </>
  );
};

export default SuppliersPage;

export const pageQuery = graphql`
  query SuppliersQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
