import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import { hexToRgb } from '../utils/hexToRgb';
import Arabesco from '../images/arabescos.svg';
import { PageWrapper, PageTitle } from '../components/pageComponents';
import {
  Tabs,
  Loader,
  SupplierInsertPhoto,
  SupplierProfileData,
  SupplierPortfolioPictureCard,
  SupplierInsertVideo,
} from '../components';
import { TrashIcon } from '../components/icons';
import { supplierContent } from '../services/api';
import { REGEX_YOUTUBE } from '../utils/regex';
import { toast } from 'react-toastify';
import { useStateValue } from '../state';

const tabsContent = [
  {
    id: 0,
    title: 'CADASTRO E HISTÓRICO',
  },
  {
    id: 1,
    title: 'INSERIR FOTOS/VÍDEO',
  },
];

const tabsContentApoiador = [
  {
    id: 0,
    title: 'CADASTRO E HISTÓRICO',
  },
];

const StyledTabs = styled(Tabs)`
  width: 100%;
`;

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  box-shadow: 0px 3px 10px 0px ${hexToRgb(theme.colors.grey2, 0.5)};
  padding: 0.8em 0 0.8em 0;
`;

const AssetContainer = styled.div`
  @media ${medias.tabletMin} {
    background-size: contain;
  }
  background-image: url(${Arabesco});
  background-size: cover;
  background-repeat-x: repeat;
  width: 100%;
  height: 2em;
  ${({ bottom }) =>
    bottom
      ? 'margin-top: 1em; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; '
      : 'margin-bottom: 1em;'}
`;

const VideoContainer = styled.iframe`
  @media ${medias.phoneMax} {
    width: 100%;
    height: 338px;
  }

  margin: 0 0 20px 0;
  width: 599px;
  height: 338px;
`;

const ItemTitle = styled.h3`
  @media ${medias.phoneMax} {
    font-size: 0.8em;
  }

  font-size: ${({ big }) => theme.fontSize * (big ? 2 : 1.06)}px;
  font-weight: bold;
  color: ${theme.colors.text_heading};
  margin-top: 0.6em;
`;

const RemovePhotosText = styled.span`
  cursor: pointer;
  margin-top: 1em;
  font-weight: bold;
`;

const PhotosCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  box-shadow: 0px 3px 10px 0px ${hexToRgb(theme.colors.grey2, 0.5)};
  margin: 1em 0 1em 0;
  padding: 1.1em 0 1.1em 0;
`;

const VideoLinkHeader = styled.p`
  font-weight: bold;
`;

const Alert = styled.div`
  background-color: ${theme.colors.primary_light};
  color: black;
  padding: 10px;
  font-size: 14px;
  border: 1px solid orange;
  margin-bottom: 10px;
`;

const SupplierProfile = () => {
  //const [ytVideoLink, setYtVideoLink] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [portfolioData, setPortfolioData] = React.useState(null);
  const [{ session }] = useStateValue();
  const [limiteFotos, setLimiteFotos] = useState(0);
  const [limiteVideos, setLimiteVideos] = useState(0);
  const [category, setCategory] = useState(1);

  const getLimiteFotos = supplierCategory => {
    switch (Number(supplierCategory)) {
      case 1:
        return 4;
      case 2:
        return 8;
      default:
        return 12;
    }
  };

  const getLimiteVideos = supplierCategory => {
    switch (Number(supplierCategory)) {
      case 1:
        return 0;
      case 2:
        return 2;
      default:
        return 4;
    }
  };

  useEffect(() => {
    const { supplierCategory } = session;
    setCategory(supplierCategory);
    const limFotos = getLimiteFotos(supplierCategory);
    const limVideos = getLimiteVideos(supplierCategory);
    setLimiteFotos(limFotos);
    setLimiteVideos(limVideos);
    // eslint-disable-next-line
  }, [session]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const res = await supplierContent.getPortfolioLength();
      if (res.ok && res.data) {
        setPortfolioData(res.data);
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  const checkVideoLink = async videoLink => {
    if (videoLink && videoLink.length > 0) {
      let input = videoLink;
      if (!videoLink.includes('vimeo')) {
        if (!REGEX_YOUTUBE.test(videoLink)) {
          toast.error('Digite um link completo do YouTube ou do Vimeo)');
          return false;
        }
        if (videoLink.substr(0, 8) !== 'https://') {
          toast.error('Por favor insira o prefixo "https://" antes do link.');
          return false;
        }
        if (videoLink.substr(8, 8) === 'youtu.be') {
          input = 'https://www.youtube.com/watch?v=' + videoLink.substr(17);
        }
      }
      setLoading(true);
      const payload = {
        input,
        type: 2,
        data: null,
      };
      const response = await supplierContent.updateMedia(payload);
      if (response.ok && response.data) {
        toast.success('Link do vídeo enviado com sucesso.');
        setPortfolioData({
          ...portfolioData,
          videoContent: [{ link: input }],
          videosCount: 1,
        });
      } else {
        toast.error('Erro ao enviar link do vídeo.');
      }
      setLoading(false);
    }
  };

  const mediaDelete = async (input, type) => {
    if (type) {
      setLoading(true);
      let payload = {
        input,
        type: type === 'photo' ? 1 : 2,
      };
      if (type === 'allPhotos') {
        payload.type = 3;
      }
      if (type === 'vimeo') {
        payload.type = 4;
      }
      const res = await supplierContent.deleteMedia(payload);
      if (res.ok && res.data) {
        if (type === 'video' || type === 'vimeo') {
          setPortfolioData({
            ...portfolioData,
            videoContent: null,
            videosCount: 0,
          });
        }
        if (type === 'photo') {
          setPortfolioData({
            ...portfolioData,
            photoContent: [
              ...portfolioData.photoContent.filter(
                i => i && !i.link.includes(input)
              ),
            ],
            photosCount: portfolioData.photosCount - 1,
          });
        }
        if (type === 'allPhotos') {
          update();
        }
        toast.success('Mídia removida com sucesso');
      } else {
        toast.error('Erro ao remover mídia.');
      }
      setLoading(false);
    } else {
      toast.error('Erro ao obter link da mídia.');
    }
  };

  const update = async () => {
    const updateRes = await supplierContent.getPortfolioLength();
    if (updateRes.ok) {
      if (updateRes.data) {
        setPortfolioData(updateRes.data);
      } else {
        setPortfolioData({
          videoContent: null,
          photoContent: null,
          photosCount: 0,
          videosCount: 0,
        });
      }
    }
  };

  const SupplierProfileMedia = () => {
    const [form, setForm] = useState({
      video: null,
    });
    const limitReached =
      portfolioData && portfolioData.photosCount >= limiteFotos;

    const hasVideo =
      portfolioData &&
      portfolioData.videosCount &&
      portfolioData.videosCount > 0;

    const videoLimitReached =
      portfolioData && portfolioData.videosCount >= limiteVideos;

    const updateField = e => {
      setForm({
        ...form,
        [e.target.name]: e.target.value,
      });
    };
    return loading ? (
      <Loader />
    ) : (
      <Card>
        <AssetContainer />
        {portfolioData && (
          <p>
            Você{' '}
            {portfolioData.photosCount > 0
              ? 'já inseriu ' + portfolioData.photosCount
              : 'ainda não inseriu nenhuma '}{' '}
            das {limiteFotos} fotos
            {category !== '1'
              ? ` e ${portfolioData.videosCount} dos ${limiteVideos} vídeos em sua cota`
              : ''}
            .
          </p>
        )}

        {category === '1' && (
          <Alert>
            Você está na Categoria Bronze e pode inserir 4 fotos e 0 vídeos.
            Caso queira inserir mais fotos e vídeos, favor solicitar a migração
            de categoria à equipe comercial da Vestidas de Branco.
          </Alert>
        )}

        {category === '2' && (
          <Alert>
            Você está na Categoria Prata e pode inserir 8 fotos e 2 vídeos. Caso
            queira inserir mais fotos e vídeos, favor solicitar a migração de
            categoria à equipe comercial da Vestidas de Branco.
          </Alert>
        )}

        {category === '3' && (
          <Alert>
            Você está na Categoria Ouro e pode inserir 12 fotos e 4 vídeos.
          </Alert>
        )}

        {limitReached ? (
          'Limite de fotos atingido.'
        ) : (
          <SupplierInsertPhoto
            count={portfolioData ? portfolioData.photosCount : 0}
            update={update}
          />
        )}

        {portfolioData &&
          portfolioData.photoContent &&
          portfolioData.photoContent.length > 0 && (
            <PhotosCard>
              <ItemTitle big>Suas Fotos</ItemTitle>
              <p>
                Coloque as fotos na ordem que desejar. (Use números diferentes.)
              </p>
              {portfolioData.photoContent.map(
                (item, index) =>
                  item && (
                    <SupplierPortfolioPictureCard
                      link={item.link}
                      texto={item.texto}
                      key={index}
                      ordem={item.ordem}
                      update={update}
                    />
                  )
              )}
              <RemovePhotosText onClick={() => mediaDelete(null, 'allPhotos')}>
                Remover todas
              </RemovePhotosText>
            </PhotosCard>
          )}
        {hasVideo && portfolioData && portfolioData.videoContent && (
          <PhotosCard>
            <ItemTitle big>Seus Vídeos</ItemTitle>
            {portfolioData.videoContent.map((videoItem, index) => {
              return (
                <>
                  {videoItem.link.includes('vimeo') ? (
                    <VideoContainer
                      src={`https://player.vimeo.com/video/${videoItem.link
                        .split('')
                        .splice(18)
                        .join('')}?title=0&portrait=0`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullscreen
                    />
                  ) : (
                    <VideoContainer
                      src={
                        'https://www.youtube.com/embed/' +
                        videoItem.link
                          .split('')
                          .splice(32)
                          .join('')
                      }
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullscreen
                    />
                  )}

                  <VideoLinkHeader>Link do vídeo {index + 1}:</VideoLinkHeader>
                  <ItemTitle>{videoItem.link}</ItemTitle>

                  <TrashIcon
                    style={{ cursor: 'pointer', marginBottom: '15px' }}
                    onClick={() =>
                      videoItem.link.includes('vimeo')
                        ? mediaDelete(
                            videoItem.link
                              .split('')
                              .splice(18)
                              .join(''),
                            'vimeo'
                          )
                        : mediaDelete(
                            videoItem.link
                              .split('')
                              .splice(32)
                              .join(''),
                            'video'
                          )
                    }
                  />
                </>
              );
            })}
          </PhotosCard>
        )}
        <>
          {Number(category) !== 1 && !videoLimitReached && (
            <SupplierInsertVideo
              form={form}
              updateField={updateField}
              checkVideoLink={checkVideoLink}
            />
          )}
        </>
      </Card>
    );
  };

  let components;

  if (Number(category) === 4) {
    components = [<SupplierProfileData key={0} />];
  } else {
    components = [
      <SupplierProfileData key={0} />,
      <SupplierProfileMedia key={1} />,
    ];
  }

  return (
    <PageWrapper>
      <PageTitle>CADASTRO DA EMPRESA</PageTitle>
      <StyledTabs
        content={Number(category) === 4 ? tabsContentApoiador : tabsContent}
        components={components}
        lineWidth={'100%'}
        onClick={() => {}}
      />
    </PageWrapper>
  );
};

export default SupplierProfile;
