import React from 'react'
import { graphql } from 'gatsby'
import { Router, Redirect } from '@reach/router'

import BrideWelcomePage from '../bridePages/BrideWelcomePage'
import BrideEventsPage from '../bridePages/BrideEventsPage'
import BrideHomePage from '../bridePages/BrideHomePage'
import BrideProfilePage from '../bridePages/BrideProfilePage'
// import initialGlobalState from '../stores';
// import mainReducer from '../state/reducers';
// import { StateProvider } from '../state';
import BrideReferPage from '../bridePages/BrideReferPage'
import ChecklistPage from '../bridePages/ChecklistPage'
import SuppliersPage from '../site/fornecedores'
import BridePrizesPage from '../bridePages/BridePrizesPage'
import BrideAdvantagesPage from '../bridePages/BrideAdvantagesPage'
import BrideFavorites from '../bridePages/BrideFavorites'
import BrideRattingPage from '../bridePages/BrideRattingPage'
import SupplierRewardsPage from '../supplierPages/SupplierRewardsPage'
import SupplierHome from '../supplierPages/SupplierHome'
import SupplierInvitePage from '../supplierPages/SupplierInvitePage'
import SupplierBridesPage from '../supplierPages/SupplierBridesPage'
import SupplierPointBridesPage from '../supplierPages/SupplierPointBridesPage'
import SupplierPrizesPage from '../supplierPages/SupplierPrizesPage'
import SupplierContractsPage from '../supplierPages/SupplierContractsPage'
import SupplierAdvantagesPage from '../supplierPages/SupplierAdvantagesPage'
import SupplierCalendarPage from '../supplierPages/SupplierCalendarPage'
import { useStateValue } from '../state'
import checkSession, { getAppRedirect } from '../services/checkSession'
import SupplierOpportunitiesPage from '../supplierPages/SupplierOpportunitiesPage'
import SupplierProfile from '../supplierPages/SupplierProfile'
import JoinBride from '../site/facaparte/noivo'
import JoinSupplier from '../site/facaparte/fornecedor'
import JoinFranchisee from '../site/facaparte/franqueado'
import SupplierPage from '../site/parceiro'
import IndicacaoNoivoPage from '../site/indicacao/noivo'
import Privacidade from '../site/privacidade'

import '../styles/bootstrap-grid.css'
import '../styles/general.css'
import '../styles/fonts.css'

const RootRedirect = () => <Redirect to={getAppRedirect()} />

export const IndexPage = () => {
  const [
    {
      session: { userId },
    },
    dispatch,
  ] = useStateValue()

  if (!userId) {
    checkSession(dispatch, true)
  }

  return (
    <React.StrictMode>
      <Router>
        <RootRedirect path="/app" />
        <BrideWelcomePage path="/app/noivos" />
        <BrideEventsPage path="/app/noivos/eventos" />
        <BrideHomePage path="/app/noivos/inicio" />
        <BrideReferPage path="/app/noivos/indicar" />
        <ChecklistPage path="/app/noivos/orcamentos" />
        <SuppliersPage path="/app/noivos/fornecedores" app />
        <BrideAdvantagesPage path="/app/noivos/vantagens" />
        <BridePrizesPage path="/app/noivos/premios" />
        <BrideFavorites path="/app/noivos/favoritos" />
        <BrideProfilePage path="/app/noivos/perfil" />
        <BrideRattingPage path="/app/noivos/avaliacoes" />
        <SupplierPage path="/app/noivos/fornecedor/:id" />
        <SupplierContractsPage path="/app/fornecedor/contratos" />
        <SupplierAdvantagesPage path="/app/fornecedor/vantagens" />
        <SupplierProfile path="/app/fornecedor/perfil" />
        <SupplierHome path="/app/fornecedor" />
        <SupplierRewardsPage path="/app/fornecedor/recompensas" />
        <SupplierInvitePage path="/app/fornecedor/convidar" />
        <SupplierBridesPage path="/app/fornecedor/noivos" />
        <SupplierPointBridesPage path="/app/fornecedor/pontuar" />
        <SupplierPrizesPage path="/app/fornecedor/premios" />
        <SupplierOpportunitiesPage path="/app/fornecedor/oportunidades" />
        <SupplierCalendarPage path="/app/fornecedor/agenda" />
        <JoinBride path="/facaparte/noivo" />
        <JoinSupplier path="/facaparte/fornecedor" />
        <JoinFranchisee path="/facaparte/franqueado" />
        <IndicacaoNoivoPage path="/indicacao/noivo/:id" />
        <Privacidade path="/privacidade" />
      </Router>
    </React.StrictMode>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query AppQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
