import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Redirect } from '@reach/router';

import { useStateValue } from '../state';
import theme from '../styles/theme';
import { bride, tracking } from '../services/api';
import {
  PageTitle,
  PageWrapper,
  CenteredText,
} from '../components/pageComponents';
import {
  SearchBar,
  Tabs,
  Loader,
  SmallAdvantagesCard,
  SmallSupplierCard,
  PrizesCard,
} from '../components';
import { medias } from '../utils/mediaQueries';

const StyledSearchbar = styled(props => <SearchBar {...props} />)`
  background-color: ${theme.colors.text_heading};
  width: 100%;
`;

const CardsContainer = styled.div`
  @media ${medias.phoneMax} {
    grid-template-columns: repeat(1, 1fr);
    width: 80%;
  }

  display: grid;
  margin-top: 20px;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 10px;
`;

const GridContainer = styled.div`
  @media ${medias.phoneMax} {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
  }

  display: grid;
  margin-top: 20px;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 10px;
`;

const tabsContent = [
  {
    id: 0,
    title: 'FORNECEDORES',
  },
  {
    id: 1,
    title: 'BENEFÍCIOS',
  },
  {
    id: 2,
    title: 'PRÊMIOS',
  },
];

const BrideFavorites = () => {
  const [suppliers, setSuppliers] = useState(null);
  const [favoriteAdvantages, setFavoriteAdvantages] = useState(null);
  const [prizes, setPrizes] = useState(null);
  const [filter, setFilter] = useState(null);
  const [inputSearched, setInputSearched] = useState(null);
  const [
    {
      session: {
        userId,
        category,
        eventFinished,
        selectedEvent,
        selectedRegion,
      },
    },
  ] = useStateValue();

  useEffect(() => {
    const fetchAdvantages = async () => {
      const response = await bride.favoritesAdvantages(selectedEvent, filter, inputSearched);

      if (response.ok && response.data) {
        setFavoriteAdvantages(response.data);
      }
    };

    const fetchPrizes = async () => {
      const response = await bride.favoritesPrizes(selectedEvent, filter, inputSearched);

      if (response.ok && response.data) {
        setPrizes(response.data);
      }
    };

    const fetchSuppliers = async () => {
      const response = await bride.favoritesSuppliers(selectedEvent, filter, inputSearched);

      if (response.ok && response.data) {
        setSuppliers(response.data);
      }
    };

    if (selectedEvent) {
      fetchAdvantages();
      fetchPrizes();
      fetchSuppliers();
    }
  }, [selectedEvent, filter, inputSearched]);

  const addFavorite = async (id, identifier) => {
    if (identifier === 'supplier') {
      const payload = {
        codEvent: selectedEvent,
        codRegion: selectedRegion,
        codSupplier: id,
      };

      const response = await bride.addFavorite(payload);
      if (response.ok) {
        return;
      }
    }

    if (identifier === 'advantages') {
      const payload = {
        codEvent: selectedEvent,
        codRegion: selectedRegion,
        codAdvantage: id,
      };

      const response = await bride.addFavorite(payload);
      if (response.ok) {
        return;
      }
    }

    if (identifier === 'prizes') {
      const payload = {
        codEvent: selectedEvent,
        codRegion: selectedRegion,
        codPrize: id,
      };

      const response = await bride.addFavorite(payload);
      if (response.ok) {
        return;
      }
    }
  };

  const removeFavorite = async (id, identifier) => {
    if (identifier === 'supplier') {
      const oldState = suppliers;
      const newSupplier = suppliers.filter(
        ({ codFornecedor: { codFornecedor } }) => codFornecedor !== id
      );
      setSuppliers(newSupplier);
      const response = await bride.removeFavorite(selectedEvent, 1, id);
      if (response.ok) {
        setSuppliers(oldState);
        return;
      }
    }

    if (identifier === 'advantages') {
      const oldState = favoriteAdvantages;
      const newAdvantages = favoriteAdvantages.filter(
        ({ codAdvantage }) => codAdvantage !== id
      );
      setFavoriteAdvantages(newAdvantages);
      const response = await bride.removeFavorite(selectedEvent, 2, id);
      if (!response.ok) {
        setFavoriteAdvantages(oldState);
        return;
      }
    }

    if (identifier === 'prizes') {
      const oldState = prizes;
      const newPrizes = prizes.filter(({ codPrize }) => codPrize !== id);
      setPrizes(newPrizes);
      const response = await bride.removeFavorite(selectedEvent, 3, id);
      if (!response.ok) {
        setPrizes(oldState);
        return;
      }
    }
  };

  const handleSupplierCards = () => {
    return suppliers.map(supplier => (
      <SmallSupplierCard
        key={supplier.codFornecedor}
        supplierData={supplier}
        app
        addFavorite={addFavorite}
        removeFavorite={removeFavorite}
        isFavoritePage
      />
    ));
  };

  const SupplierCards = () =>
    suppliers.length ? (
      <CardsContainer>{handleSupplierCards()}</CardsContainer>
    ) : (
      <CenteredText>Nenhum fornecedor favoritado!</CenteredText>
    );

  const AdvantagesCards = () =>
    favoriteAdvantages.length > 0 ? (
      <GridContainer>
        {favoriteAdvantages.map(adv => (
          <SmallAdvantagesCard
            key={adv.codAdvantage}
            advantageData={adv}
            category={category}
            removeFavorite={removeFavorite}
            addFavorite={addFavorite}
            isFavoritePage
          />
        ))}
      </GridContainer>
    ) : (
      <CenteredText>Nenhum benefício favoritado!</CenteredText>
    );

  const PrizesCards = () =>
    prizes.length > 0 ? (
      prizes.map(data => (
        <PrizesCard
          key={data.codPrize}
          prizeData={data}
          removeFavorite={removeFavorite}
          addFavorite={addFavorite}
          isFavoritePage
        />
      ))
    ) : (
      <CenteredText>Nenhum prêmio favoritado!</CenteredText>
    );

  const components = [
    <SupplierCards key={0} />,
    <AdvantagesCards key={1} />,
    <PrizesCards key={2} />,
  ];

  if (eventFinished) {
    return <Redirect to="/app/noivos/inicio" />;
  }

  const handleSearch = async (searchedValue, dropdown) => {
    setInputSearched(null);
    if (dropdown) {
      setFilter(dropdown.id);
    }
    if (searchedValue) {
      setInputSearched(searchedValue);
    }

    const input = searchedValue ? searchedValue : dropdown.value
   
    await tracking.search({
      userId,
      eventId: selectedEvent,
      input: input,
      module: 'favorito',
    });
  };

  return (
    <PageWrapper>
      <PageTitle>FAVORITOS</PageTitle>
      {prizes && favoriteAdvantages && suppliers && !eventFinished ? (
        <>
          <StyledSearchbar
            onClickClear={() => setFilter(null)}
            onClickSearch={handleSearch}
            placeholder="Palavra-chave?"
            selectWhoFav
            favorites
          />
          <Tabs
            content={tabsContent}
            onClick={() => {}}
            components={components}
          />
        </>
      ) : (
        <Loader />
      )}
    </PageWrapper>
  );
};

export default BrideFavorites;
