import React, { useRef, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { navigate } from '@reach/router';
import {
  PageTitle,
  PageWrapper,
  CenteredText,
} from '../components/pageComponents';
import CardContainer from '../components/CardContainer';
import MaterialTable from '../components/MaterialTable';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {
  Tabs,
  Loader,
  Pagination,
  SearchBar,
  BrideFilterModal,
  Pagination2,
  TablePagination,
} from '../components';
import theme from '../styles/theme';
import { supplierContent, supplier } from '../services/api';
import SupplierBridesCard from '../components/SupplierBrideCard';
import SupplierBrideRow from '../components/SupplierBrideRow';
import useFetch, { method } from '../hooks/useFetch';
import { urls } from '../services/urls';
import { scrollToRef } from '../hooks';
import { toast } from 'react-toastify';
import { useStateValue } from '../state';
import { REGEX_NUMBER } from '../utils/regex';
const tabsContent = [
  {
    id: 0,
    title: 'DISPONÍVEIS',
  },
  {
    id: 2,
    title: 'ENTREI EM CONTATO',
  },
  {
    id: 1,
    title: 'CONVIDEI',
  },
];

const columns = [
  { id: 'id', label: '#ID', minWidth: '3vw' },
  { id: 'event', label: 'EVENTO', minWidth: '20px' },
  { id: 'date', label: 'DATA', minWidth: '7px' },
  { id: 'bride', label: 'NOIVO', minWidth: '11vw' },
  { id: 'email', label: 'EMAIL', minWidth: '9vw' },
  { id: 'phone', label: 'FONE', minWidth: '8vw' },
  { id: 'ceremony', label: 'CERIMÔNIA', minWidth: '11vw' },
  { id: 'reception', label: 'RECEPÇÃO', minWidth: '12vw' },
  { id: 'guests', label: 'CONVIDADOS', minWidth: '3vw' },
  { id: 'investment', label: 'INVESTIMENTO', minWidth: '8vw' },
  { id: 'contracts', label: 'PONTUAR', minWidth: '5vw' },
  { id: 'contacted', label: 'CONTATADO', minWidth: '5vw' },
  { id: 'indicationType', label: 'INDICAÇÃO', minWidth: '5vw' },
  { id: 'indicationBy', label: 'POR', minWidth: '5vw' },
];

const SumContainer = styled.section`
  width: 90%;
  margin-top: 10px;
  background: ${theme.colors.text_heading};
  color: white;
  text-align: center;
  padding: 1.5rem 0.5rem;
  b,
  h2 {
    color: ${theme.colors.primary};
  }
  h2 {
    margin: 1rem 0;
  }
  hr {
    background: white;
    opacity: 0.5;
    width: 70%;
    margin: 0.5rem auto;
  }
`;

const StyledTabs = styled(props => <Tabs {...props} />)`
  margin-top: 1em;
`;

const StyledDiv = styled.div`
  width: 100%;
`;

const StyledSpan = styled.span`
  font-weight: bold;
  color: ${theme.colors.primary};
`;

const TableCard = styled.div`
  width: auto;
  border: 1px dotted black;
  overflow-x: scroll;
  white-space: nowrap;
  font-family: ${theme.fontFamilyBody};
`;

const StyledP = styled.div`
  padding-top: 1em;
  .change-btn:hover {
    font-weight: bold;
    text-decoration: underline;
  }
`;

const SupplierBridesPage = () => {
  const [initialPage, setInitialPage] = React.useState(1);
  const [order, setOrder] = React.useState('eventDate');

  const [filter, setFilter] = React.useState(null);
  const [segmentFilter, setSegmentFilter] = React.useState(null);
  const [regionFilter, setRegionFilter] = React.useState(null);
  const [weddingDateFilter, setWeddingDateFilter] = React.useState(null);
  const [brideNameEmailFilter, setBrideNameEmailFilter] = React.useState(null);
  const [registerFilter, setRegisterFilter] = React.useState(null);
  const [receptionFilter, setReceptionFilter] = React.useState(null);
  const [invitedFilter, setInvitedFilter] = React.useState(null);
  const [supplierFilter, setSupplierFilter] = React.useState(null);
  const [investmentFilter, setInvestmentFilter] = React.useState(null);
  const [eventCodeFilter, setEventCodeFilter] = React.useState(null);

  const [avaliableBrides, setAvaliableBrides] = React.useState(null);
  const [invitedBrides, setInvitedBrides] = React.useState(null);
  const [contactedBrides, setContactedBrides] = React.useState(null);
  const [reportAvaliableData, setReportAvaliableData] = React.useState(null);
  const [reportInvitedData, setReportInvitedData] = React.useState(null);
  const [reportContactedData, setReportContactedData] = React.useState(null);

  const [activeTab, setActiveTab] = React.useState(0);
  const [searched, setSearched] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [filterModal, setFilterModal] = React.useState(false);
  const [regionData, setRegionData] = React.useState(false);
  const [segmentData, setSegmentsData] = React.useState(false);
  const [otherSuppliers, setOtherSuppliers] = React.useState(false);

  const [cardDisplay, setDisplay] = React.useState(false);

  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);

  const resPerPage = 10;
  const today = moment().format('YYYY-MM-DD');
  const [
    {
      session: { supplierCategory },
    },
  ] = useStateValue();

  useEffect(() => {
    if (!supplierCategory || supplierCategory === 'undefined') {
      navigate('/app/fornecedor');
    }
    setIsLoading(true);
    return () => {
      setAvaliableBrides(null);
      setReportAvaliableData(null);
      setInvitedBrides(null);
      setReportInvitedData(null);
      setIsLoading(false);
    };
  }, [supplierCategory, clearFilter]);

  const fetchSupplierBrides = async pageNumber => {
    setInitialPage(pageNumber);
    setIsLoading(true);

    if (activeTab === 0) {
      if (!filter) {
        const response = await supplier.getBridesFilteredByType(
          order,
          'potenciais',
          pageNumber,
          resPerPage,
          supplierCategory
        );

        if (response.ok && response.data) {
          changeState(response.data);
          setIsLoading(false);
          return;
        }
      }

      if (filter === 'segment') {
        const res = await supplierContent.getBridesBySegment(
          order,
          'potenciais',
          segmentFilter,
          pageNumber,
          resPerPage,
          supplierCategory
        );

        if (res.ok && res.data) {
          changeState(res.data);
          setFilterModal(false);
          setIsLoading(false);
          setSearched(true);
          return;
        }
      }

      if (filter === 'region') {
        const res = await supplierContent.getBridesByRegion(
          order,
          'potenciais',
          regionFilter,
          pageNumber,
          resPerPage,
          supplierCategory
        );

        if (res.ok && res.data) {
          changeState(res.data);
          setFilterModal(false);
          setIsLoading(false);
          setSearched(true);
          return;
        }
      }

      if (filter === 'weddingDate') {
        const initialDate = moment(weddingDateFilter.from).format('YYYY-MM-DD');
        const finalDate = moment(weddingDateFilter.to).format('YYYY-MM-DD');
        let tp = 'potenciais';
        if (initialDate < today) {
          tp = 'casados';
        }
        const res = await supplierContent.getBridesByDate(
          order,
          tp,
          initialDate,
          finalDate,
          pageNumber,
          resPerPage,
          supplierCategory
        );

        if (res.ok && res.data) {
          changeState(res.data);
          setFilterModal(false);
          setIsLoading(false);
          setSearched(true);
          return;
        }
      }

      if (filter === 'bride') {
        const { name, email } = brideNameEmailFilter;
        const res = await supplierContent.getBridesByBrides(
          order,
          'potenciais',
          name,
          email,
          pageNumber,
          resPerPage,
          supplierCategory
        );

        if (res.ok && res.data) {
          changeState(res.data);
          setFilterModal(false);
          setIsLoading(false);
          setSearched(true);
          return;
        }
      }

      if (filter === 'register') {
        const initialDate = moment(registerFilter.from).format('YYYY-MM-DD');
        const finalDate = moment(registerFilter.to).format('YYYY-MM-DD');
        const res = await supplierContent.getBridesByRegister(
          order,
          'potenciais',
          initialDate,
          finalDate,
          pageNumber,
          resPerPage,
          supplierCategory
        );

        if (res.ok && res.data) {
          changeState(res.data);
          setFilterModal(false);
          setIsLoading(false);
          setSearched(true);
          return;
        }
      }

      if (filter === 'reception') {
        const res = await supplierContent.getBridesByReception(
          order,
          'potenciais',
          receptionFilter,
          pageNumber,
          resPerPage,
          supplierCategory
        );

        if (res.ok && res.data) {
          changeState(res.data);
          setFilterModal(false);
          setIsLoading(false);
          setSearched(true);
          return;
        }
      }

      if (filter === 'invited') {
        const match = invitedFilter.match(REGEX_NUMBER);
        const invitedMin = match[0];
        const invitedMax = match[1];
        const res = await supplierContent.getBridesByInvitation(
          order,
          'potenciais',
          invitedMin,
          invitedMax,
          pageNumber,
          resPerPage,
          supplierCategory
        );

        if (res.ok && res.data) {
          changeState(res.data);
          setFilterModal(false);
          setIsLoading(false);
          setSearched(true);
          return;
        }
      }

      if (filter === 'supplier') {
        const res = await supplierContent.getBridesBySupplier(
          order,
          supplierFilter,
          pageNumber,
          resPerPage,
          supplierCategory
        );

        if (res.ok && res.data) {
          changeState(res.data, res.data);
          setFilterModal(false);
          setIsLoading(false);
          setSearched(true);
          return;
        }
      }

      if (filter === 'investment') {
        const res = await supplierContent.getBridesByInvestment(
          order,
          'potenciais',
          investmentFilter.from,
          investmentFilter.to,
          pageNumber,
          resPerPage,
          supplierCategory
        );

        if (res.ok && res.data) {
          changeState(res.data);
          setFilterModal(false);
          setIsLoading(false);
          setSearched(true);
          return;
        }
      }

      if (filter === 'eventCode') {
        const res = await supplierContent.getBridesByEventCode(
          order,
          'potenciais',
          eventCodeFilter,
          pageNumber,
          resPerPage,
          supplierCategory
        );

        if (res.ok && res.data) {
          changeState(res.data);
          setFilterModal(false);
          setIsLoading(false);
          setSearched(true);
          return;
        }
      }
    }

    if (activeTab === 1) {
      if (!filter) {
        const response = await supplier.getBridesFilteredByType(
          order,
          'convidados',
          pageNumber,
          resPerPage,
          supplierCategory
        );

        if (response.ok && response.data) {
          changeState(null, response.data);
          setActiveTab(activeTab);
          setIsLoading(false);
          return;
        }
      }

      if (filter === 'region') {
        const res = await supplierContent.getBridesByRegion(
          order,
          'convidados',
          regionFilter,
          pageNumber,
          resPerPage,
          supplierCategory
        );

        if (res.ok && res.data) {
          changeState(null, res.data);
          setFilterModal(false);
          setActiveTab(activeTab);
          setIsLoading(false);
          setSearched(true);
          return;
        }
      }

      if (filter === 'weddingDate') {
        const initialDate = moment(weddingDateFilter.from).format('YYYY-MM-DD');
        const finalDate = moment(weddingDateFilter.to).format('YYYY-MM-DD');
        let res;
        let tp = 'convidados';
        if (initialDate < today) {
          tp = 'casados';
          res = await supplierContent.getBridesByDate(
            order,
            tp,
            initialDate,
            finalDate,
            pageNumber,
            resPerPage,
            supplierCategory
          );

          if (res.ok && res.data) {
            changeState(null, null, null, res.data);
            setFilterModal(false);
            setActiveTab(3);
            setIsLoading(false);
            setSearched(true);
            return;
          }
        }
        res = await supplierContent.getBridesByDate(
          order,
          tp,
          initialDate,
          finalDate,
          pageNumber,
          resPerPage,
          supplierCategory
        );

        if (res.ok && res.data) {
          changeState(null, res.data);
          setFilterModal(false);
          setActiveTab(activeTab);
          setIsLoading(false);
          setSearched(true);
          return;
        }
      }

      if (filter === 'bride') {
        const { name, email } = brideNameEmailFilter;
        const res = await supplierContent.getBridesByBrides(
          order,
          'convidados',
          name,
          email,
          pageNumber,
          resPerPage,
          supplierCategory
        );

        if (res.ok && res.data) {
          changeState(null, res.data);
          setFilterModal(false);
          setActiveTab(activeTab);
          setIsLoading(false);
          setSearched(true);
          return;
        }
      }

      if (filter === 'register') {
        const initialDate = moment(registerFilter.from).format('YYYY-MM-DD');
        const finalDate = moment(registerFilter.to).format('YYYY-MM-DD');
        const res = await supplierContent.getBridesByRegister(
          order,
          'convidados',
          initialDate,
          finalDate,
          pageNumber,
          resPerPage,
          supplierCategory
        );

        if (res && res.ok && res.data) {
          changeState(null, res.data);
          setFilterModal(false);
          setActiveTab(activeTab);
          setIsLoading(false);
          setSearched(true);
          return;
        }
      }

      if (filter === 'reception') {
        const res = await supplierContent.getBridesByReception(
          order,
          'convidados',
          receptionFilter,
          pageNumber,
          resPerPage,
          supplierCategory
        );

        if (res.ok && res.data) {
          changeState(null, res.data);
          setFilterModal(false);
          setActiveTab(activeTab);
          setIsLoading(false);
          setSearched(true);
          return;
        }
      }

      if (filter === 'investment') {
        const res = await supplierContent.getBridesByInvestment(
          order,
          'convidados',
          investmentFilter.from,
          investmentFilter.to,
          pageNumber,
          resPerPage,
          supplierCategory
        );

        if (res.ok && res.data) {
          changeState(res.data);
          setFilterModal(false);
          setIsLoading(false);
          setSearched(true);
          return;
        }
      }

      /*if (filter === 'eventCode') {
        const res = await supplierContent.getBridesByCode(
          order,
          'convidados',
          eventCodeFilter,
          pageNumber,
          resPerPage,
          supplierCategory
        );

        if (res.ok && res.data) {
          changeState(res.data);
          setFilterModal(false);
          setIsLoading(false);
          setSearched(true);
          return;
        }
      }*/
    }
    if (activeTab === 2) {
      const response = await supplierContent.getContactedBrides(
        pageNumber,
        resPerPage
      );

      if (response.ok && response.data) {
        changeState(null, null, response.data);
        setActiveTab(activeTab);
        setIsLoading(false);
        return;
      }
    }
  };

  const { doUpdate } = useFetch({
    url: urls.supplierContent.getBridesFilteredByType(
      order,
      'potenciais',
      1,
      resPerPage,
      supplierCategory
    ),
    method: method.GET,
    condition: () => !avaliableBrides,
    callback: data => {
      changeState(data);
      setIsLoading(false);
    },
  });

  const { doUpdate: doUpdateInvited } = useFetch({
    url: urls.supplierContent.getBridesFilteredByType(
      order,
      'convidados',
      1,
      resPerPage,
      supplierCategory
    ),
    method: method.GET,
    condition: () => !invitedBrides,
    callback: data => changeState(null, data),
  });

  useFetch({
    url: urls.supplierContent.supplierRegions,
    method: method.GET,
    condition: () => !regionData,
    callback: data => setRegionData(data),
  });

  useFetch({
    url: urls.supplierContent.getOtherSuppliers,
    method: method.GET,
    condition: () => !otherSuppliers,
    callback: data => setOtherSuppliers(data),
  });

  useFetch({
    url: urls.supplierContent.supplierSegments,
    method: method.GET,
    condition: () => !regionData,
    callback: data => setSegmentsData(data),
  });

  useFetch({
    url: urls.supplierContent.getContactedBrides,
    method: method.GET,
    condition: () => !contactedBrides,
    callback: data => {
      if (data) {
        const { eligibleEvents, count } = data;
        setContactedBrides(eligibleEvents);
        setReportContactedData(count);
      }
    },
  });

  const changeState = (available, invited, contacted) => {
    if (available) {
      setAvaliableBrides(
        available !== undefined ? available.eligibleEvents : undefined
      );
      setReportAvaliableData(
        available !== undefined ? available.count : undefined
      );
    }
    if (invited) {
      setInvitedBrides(
        invited !== undefined ? invited.eligibleEvents : undefined
      );
      setReportInvitedData(invited !== undefined ? invited.count : undefined);
    }
    if (contacted) {
      setContactedBrides(
        contacted !== undefined ? contacted.eligibleEvents : undefined
      );
      setReportContactedData(
        contacted !== undefined ? contacted.count : undefined
      );
    }
  };

  const updateOportunities = () => {
    setAvaliableBrides(null);
    doUpdate();
  };

  const onPageChange = pageNumber => {
    console.log('page changed from previous to' + pageNumber);
    setIsLoading(true);
    fetchSupplierBrides(pageNumber);
  };

  const markAsContacted = async id => {
    if (id !== undefined && id !== null) {
      const response = await supplierContent.markBrideAsContacted(id);
      response.ok
        ? toast.success(`Sucesso ao adicionar à Contatados`)
        : toast.error('Erro ao salvar!');
    }
  };

  const unmarkAsContacted = async id => {
    if (id !== undefined && id !== null) {
      const response = await supplierContent.unmarkBrideAsContacted(id);
      response.ok
        ? toast.success(`Sucesso ao remover de Contatados`)
        : toast.error('Erro ao remover!');
    }
  };

  const renderAvaliable = (bride, index) =>
    bride && (
      <SupplierBridesCard
        listIndex={index}
        key={bride.id}
        cardData={bride}
        type={0}
        segmentData={segmentData}
        regionsData={regionData}
        updateOportunities={updateOportunities}
        MarkClick={() => markAsContacted(bride.id)}
      />
    );

  const renderAvailableRow = (bride, index) =>
    bride && (
      <SupplierBrideRow
        key={bride.id}
        cardData={bride}
        type={0}
        listIndex={index}
        segmentData={segmentData}
        regionsData={regionData}
        updateOportunities={updateOportunities}
        MarkClick={() => markAsContacted(bride.id)}
        UnmarkClick={() => unmarkAsContacted(bride.id)}
      />
    );

  const renderBrides = (bride, index) =>
    bride && (
      <SupplierBridesCard
        listIndex={index}
        key={bride.id}
        cardData={bride}
        type={1}
      />
    );

  const renderBridesRow = (bride, index) =>
    bride && (
      <SupplierBrideRow
        key={bride.id}
        cardData={bride}
        type={1}
        listIndex={index}
        segmentData={segmentData}
        regionsData={regionData}
        updateOportunities={updateOportunities}
        MarkClick={() => markAsContacted(bride.id)}
        UnmarkClick={() => unmarkAsContacted(bride.id)}
      />
    );

  const renderContacted = (bride, index) =>
    bride && (
      <SupplierBridesCard
        listIndex={index}
        key={bride.id}
        cardData={bride}
        type={2}
        segmentData={segmentData}
        regionsData={regionData}
        updateOportunities={updateOportunities}
        UnmarkClick={() => unmarkAsContacted(bride.id)}
      />
    );

  const renderContactedRow = (bride, index) =>
    bride && (
      <SupplierBrideRow
        key={bride.id}
        cardData={bride}
        type={2}
        listIndex={index}
        segmentData={segmentData}
        regionsData={regionData}
        updateOportunities={updateOportunities}
        UnmarkClick={() => unmarkAsContacted(bride.id)}
      />
    );

  const CardComponent = ({ data, type }) =>
    data && data.length > 0 ? (
      type === 0 ? (
        <Pagination
          itemsPerPage={10}
          data={data}
          renderItem={renderAvaliable}
          showButtons={false}
        />
      ) : type === 1 ? (
        <Pagination
          itemsPerPage={10}
          data={data}
          renderItem={renderBrides}
          showButtons={false}
        />
      ) : type === 2 ? (
        <Pagination
          itemsPerPage={30}
          data={data}
          renderItem={renderContacted}
          showButtons={false}
        />
      ) : (
        ''
      )
    ) : (
      <CenteredText>Nenhum noivo disponivel!</CenteredText>
    );

  const TableComponent = ({ data, type }) =>
    data && data.length > 0 && data !== undefined ? (
      <TableCard style={{ maxHeight: '50vh' }}>
        <MaterialTable>
          <TableHead>
            <TableRow>
              {columns.map(col => {
                if (
                  type === 1 &&
                  (col.id === 'indicationType' ||
                    col.id === 'indicationBy' ||
                    col.id === 'contracts' ||
                    col.id === 'contacted')
                ) {
                  return;
                } else {
                  return (
                    <TableCell
                      key={col.id}
                      style={{ paddingLeft: '0.6rem !important' }}
                    >
                      {col.label}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {type === 0 ? (
              <TablePagination
                itemsPerPage={10}
                data={data}
                renderItem={renderAvailableRow}
                showButtons={false}
              />
            ) : type === 1 ? (
              <TablePagination
                itemsPerPage={10}
                data={data}
                renderItem={renderBridesRow}
                showButtons={false}
              />
            ) : type === 2 ? (
              <TablePagination
                itemsPerPage={30}
                data={data}
                renderItem={renderContactedRow}
                showButtons={false}
              />
            ) : (
              ''
            )}
          </TableBody>
        </MaterialTable>
      </TableCard>
    ) : (
      <CenteredText>Nenhum noivo disponivel!</CenteredText>
    );

  const components = [
    <CardContainer key={0}>
      <CardComponent data={avaliableBrides} type={0} />
    </CardContainer>,
    <CardContainer key={1}>
      <CardComponent data={invitedBrides} type={1} />
    </CardContainer>,
    <CardContainer key={2}>
      <CardComponent data={contactedBrides} type={2} />
    </CardContainer>,
  ];

  const tableComponents = [
    <CardContainer key={0}>
      <TableComponent data={avaliableBrides} type={0} />
    </CardContainer>,
    <CardContainer key={1}>
      <TableComponent data={invitedBrides} type={1} />
    </CardContainer>,
    <CardContainer key={2}>
      <TableComponent data={contactedBrides} type={2} />
    </CardContainer>,
  ];

  const openModal = () => {
    setFilterModal(true);
  };

  const handleTabChange = tab => {
    setActiveTab(tab);
    setInitialPage(1);
  };

  const doEmptySearch = async () => {
    const response = await supplier.getBridesFilteredByType(
      order,
      'potenciais',
      1,
      resPerPage,
      supplierCategory
    );
    const response2 = await supplier.getBridesFilteredByType(
      order,
      'convidados',
      1,
      resPerPage,
      supplierCategory
    );

    if (response.ok && response.data && response2.ok && response2.data) {
      changeState(response.data, response2.data);
      setIsLoading(false);
      return;
    }
  };

  const handleSearch = async input => {
    setInitialPage(1);
    setAvaliableBrides(null);
    setReportAvaliableData(null);
    setInvitedBrides(null);
    setReportInvitedData(null);
    setIsLoading(true);

    let response, response2;

    if (!input) {
      doEmptySearch();
      return;
    }

    if (input.includes('@')) {
      response = await supplierContent.getBridesByBrides(
        order,
        'convidados',
        null,
        input,
        initialPage,
        resPerPage,
        supplierCategory
      );
      response2 = await supplierContent.getBridesByBrides(
        order,
        'potenciais',
        null,
        input,
        initialPage,
        resPerPage,
        supplierCategory
      );
    } else {
      response = await supplierContent.getBridesByName(
        order,
        'convidados',
        input,
        initialPage,
        resPerPage,
        supplierCategory
      );
      response2 = await supplierContent.getBridesByName(
        order,
        'potenciais',
        input,
        initialPage,
        resPerPage,
        supplierCategory
      );
    }

    if (response.ok && response.data && response2.ok && response2.data) {
      changeState(response2.data, response.data);
      setFilterModal(false);
      setIsLoading(false);
      setSearched(true);
      return;
    }
  };

  const handleChangeOrder = async value => {
    setOrder(value);
  };

  const handleClear = async () => {
    setAvaliableBrides(null);
    setReportAvaliableData(null);
    setInvitedBrides(null);
    setReportInvitedData(null);
    setIsLoading(true);
    setInitialPage(1);

    const response = await supplierContent.getBridesFilteredByType(
      order,
      'convidados'
    );
    const response2 = await supplierContent.getBridesFilteredByType(
      order,
      'potenciais'
    );

    if (response.ok && response.data && response2.ok && response2.data) {
      changeState(response2.data, response.data);
      setSearched(false);
      setIsLoading(false);
      return;
    }
  };

  const handleFilter = async (type, data) => {
    toast.success('Aguarde... Filtrando resultados');
    setFilter(type);
    setInitialPage(1);
    setAvaliableBrides(null);
    setReportAvaliableData(null);
    setFilterModal(false);
    if (type === 'segment') {
      setSegmentFilter(data);
      setIsLoading(true);
      const res = await supplierContent.getBridesBySegment(
        order,
        'potenciais',
        data,
        initialPage,
        resPerPage,
        supplierCategory
      );

      if (res.ok) {
        if (res.data && !(res.data.count === 0)) {
          toast.info('Nenhum noivo encontrado');
          setIsLoading(false);
          return clearFilter();
        }
        changeState(res.data);
        setIsLoading(false);
        setSearched(true);
        return;
      }
    }

    if (type === 'region') {
      setRegionFilter(data);
      setIsLoading(true);
      const response = await supplierContent.getBridesByRegion(
        order,
        'convidados',
        data,
        initialPage,
        resPerPage,
        supplierCategory
      );
      const response2 = await supplierContent.getBridesByRegion(
        order,
        'potenciais',
        data
      );

      if (response.ok && response.data && response2.ok && response2.data) {
        changeState(response2.data, response.data);
        setIsLoading(false);
        setSearched(true);
        return;
      }
    }

    if (type === 'weddingDate') {
      setWeddingDateFilter(data);
      setIsLoading(true);
      const initialDate = moment(data.from).format('YYYY-MM-DD');
      const finalDate = moment(data.to).format('YYYY-MM-DD');
      const today = moment().format('YYYY-MM-DD');

      if (initialDate < today) {
        const response = await supplierContent.getBridesByDate(
          order,
          'casados',
          initialDate,
          finalDate,
          1,
          resPerPage,
          supplierCategory
        );

        if (response.ok && response.data) {
          changeState(null, null, null, response.data);
          setIsLoading(false);
          setSearched(true);
          return;
        }
      }
      const response = await supplierContent.getBridesByDate(
        order,
        'convidados',
        initialDate,
        finalDate,
        initialPage,
        resPerPage,
        supplierCategory
      );

      const response2 = await supplierContent.getBridesByDate(
        order,
        'potenciais',
        initialDate,
        finalDate
      );

      if (response.ok && response.data && response2.ok && response2.data) {
        changeState(response2.data, response.data);
        setIsLoading(false);
        setSearched(true);
        return;
      }
    }

    if (type === 'bride') {
      setBrideNameEmailFilter(data);
      setIsLoading(true);
      const { name, email } = data;

      const response = await supplierContent.getBridesByBrides(
        order,
        'convidados',
        name,
        email,
        initialPage,
        resPerPage,
        supplierCategory
      );

      const response2 = await supplierContent.getBridesByBrides(
        order,
        'potenciais',
        name,
        email,
        initialPage,
        resPerPage,
        supplierCategory
      );

      if (response.ok && response.data && response2.ok && response2.data) {
        changeState(response2.data, response.data);
        setIsLoading(false);
        setSearched(true);
        return;
      }
    }

    if (type === 'register') {
      setRegisterFilter(data);
      setIsLoading(true);
      const initialDate = moment(data.from).format('YYYY-MM-DD');
      const finalDate = moment(data.to).format('YYYY-MM-DD');
      const response = await supplierContent.getBridesByRegister(
        order,
        'convidados',
        initialDate,
        finalDate,
        initialPage,
        resPerPage,
        supplierCategory
      );

      const response2 = await supplierContent.getBridesByRegister(
        order,
        'potenciais',
        initialDate,
        finalDate,
        initialPage,
        resPerPage,
        supplierCategory
      );

      if (response.ok && response.data && response2.ok && response2.data) {
        changeState(response2.data, response.data);
        setIsLoading(false);
        setSearched(true);
        return;
      }
    }

    if (type === 'reception') {
      setReceptionFilter(data);
      setIsLoading(true);
      const response = await supplierContent.getBridesByReception(
        order,
        'convidados',
        data
      );

      const response2 = await supplierContent.getBridesByReception(
        order,
        'potenciais',
        data,
        initialPage,
        resPerPage,
        supplierCategory
      );

      if (response.ok && response.data && response2.ok && response2.data) {
        changeState(response2.data, response.data);
        setIsLoading(false);
        setSearched(true);
        return;
      }
    }

    if (type === 'invited') {
      const match = data.match(REGEX_NUMBER);
      const invitedMin = match[0];
      const invitedMax = match[1];
      setInvitedFilter(data);
      setIsLoading(true);
      const res = await supplierContent.getBridesByInvitation(
        order,
        'potenciais',
        invitedMin,
        invitedMax,
        initialPage,
        resPerPage,
        supplierCategory
      );

      if (res.ok) {
        changeState(res.data);
        setIsLoading(false);
        setSearched(true);
        return;
      }
    }

    if (type === 'supplier') {
      setSupplierFilter(data);
      setIsLoading(true);
      const res = await supplierContent.getBridesBySupplier(
        order,
        data,
        initialPage,
        resPerPage,
        supplierCategory
      );

      if (res.ok) {
        if (res.data && res.data.count == 0) {
          toast.info('Nenhum noivo encontrado para o fornecedor selecionado');
          setIsLoading(false);
          return clearFilter();
        }
        changeState(res.data, null, null);
        setIsLoading(false);
        setSearched(true);
        return;
      }
    }

    if (type === 'investment') {
      setInvestmentFilter(data);
      setIsLoading(true);

      const res = await supplierContent.getBridesByInvestment(
        order,
        'potenciais',
        data.from,
        data.to,
        initialPage,
        resPerPage,
        supplierCategory
      );

      if (res.ok) {
        if (res.data && res.data.count == 0) {
          toast.error(
            'Nenhum noivo encontrado para estes valores. Recarregando noivos disponíveis...'
          );
          setIsLoading(false);
          return clearFilter();
        }
        changeState(res.data);
        setIsLoading(false);
        setSearched(true);
        return;
      }
    }

    if (type === 'eventCode') {
      setEventCodeFilter(data);
      setIsLoading(true);

      const res = await supplierContent.getBridesByEventCode(
        order,
        'potenciais',
        data,
        initialPage,
        resPerPage,
        supplierCategory
      );

      if (res.ok) {
        if (res.data && res.data.count == 0) {
          toast.error('Nenhum evento encontrado com este código!');
          setIsLoading(false);
          toast.info('Recarregando noivos disponíveis...');
          return clearFilter();
        }
        changeState(res.data);
        setIsLoading(false);
        setSearched(true);
        return;
      }
    }
  };

  const clearFilter = useCallback(async () => {
    setAvaliableBrides(null);
    setReportAvaliableData(null);
    setInvitedBrides(null);
    setReportInvitedData(null);
    doUpdate();
    doUpdateInvited();
    setFilterModal(false);
  }, [
    setAvaliableBrides,
    setReportAvaliableData,
    setInvitedBrides,
    setReportInvitedData,
    doUpdate,
    doUpdateInvited,
    setFilterModal,
  ]);

  const handleDisplay = displayType => {
    setIsLoading(true);
    setDisplay(displayType);
    setIsLoading(false);
  };

  return (
    <PageWrapper>
      <PageTitle>Noivos</PageTitle>
      <BrideFilterModal
        loading={isLoading}
        suppliersData={otherSuppliers}
        regionsData={regionData}
        segmentsData={segmentData}
        onClickFilter={handleFilter}
        onClickClear={clearFilter}
        opened={filterModal}
        closeModal={() => setFilterModal(false)}
      />
      <StyledDiv ref={myRef}>
        <SearchBar
          filter
          placeholder="Nome ou email do noivo"
          searched={searched}
          onClickClear={handleClear}
          onClickFilter={openModal}
          onClickSearch={input => handleSearch(input)}
          handleChangeOrder={handleChangeOrder}
        />
      </StyledDiv>
      <StyledP>
        Modo de exibição atual:{' '}
        <StyledSpan>{!cardDisplay ? 'Tabela' : 'Card'}</StyledSpan>{' '}
        <button
          className="change-btn"
          onClick={() => handleDisplay(!cardDisplay)}
        >
          {!cardDisplay ? 'Trocar para Card' : 'Trocar para Tabela'}
        </button>
      </StyledP>
      <StyledP>
        <button className="change-btn" onClick={() => clearFilter()}>
          Recarregar
        </button>
      </StyledP>

      {!isLoading ? (
        <>
          {avaliableBrides || invitedBrides || contactedBrides ? (
            <>
              {cardDisplay ? (
                <StyledTabs
                  content={tabsContent}
                  components={components}
                  onClick={activeTab => handleTabChange(activeTab)}
                  activeTab={activeTab}
                  hasActiveTab={true}
                />
              ) : (
                <StyledTabs
                  content={tabsContent}
                  components={tableComponents}
                  onClick={activeTab => handleTabChange(activeTab)}
                  activeTab={activeTab}
                  hasActiveTab={true}
                />
              )}
            </>
          ) : (
            <CenteredText>Nenhum noivo disponivel!</CenteredText>
          )}
        </>
      ) : (
        <Loader />
      )}
      {avaliableBrides &&
        avaliableBrides.length > 0 &&
        reportAvaliableData &&
        reportAvaliableData > 0 &&
        activeTab === 0 && (
          <Pagination2
            items={avaliableBrides}
            itemsTotal={reportAvaliableData}
            onChangePage={onPageChange}
            pageSize={resPerPage}
            scrollToRef={executeScroll}
            initialPage={initialPage}
          />
        )}
      {invitedBrides &&
        invitedBrides.length > 0 &&
        reportInvitedData &&
        reportInvitedData > 0 &&
        activeTab === 1 && (
          <Pagination2
            items={invitedBrides}
            itemsTotal={reportInvitedData}
            onChangePage={onPageChange}
            pageSize={resPerPage}
            scrollToRef={executeScroll}
            initialPage={initialPage}
          />
        )}
      {contactedBrides &&
        contactedBrides.length > 0 &&
        reportContactedData &&
        reportContactedData > 0 &&
        activeTab === 2 && (
          <Pagination2
            items={contactedBrides}
            itemsTotal={reportContactedData}
            onChangePage={onPageChange}
            pageSize={resPerPage || 15}
            scrollToRef={executeScroll}
            initialPage={initialPage}
          />
        )}
      <SumContainer>
        <p>
          Noivos disponíveis:{' '}
          <b>{reportAvaliableData ? reportAvaliableData : '-'}</b>
        </p>
        <p>
          Noivos convidados:{' '}
          <b>{reportInvitedData ? reportInvitedData : '-'}</b>
        </p>
        <p>
          Noivos contatados:{' '}
          <b>{reportContactedData ? reportContactedData : '-'}</b>
        </p>
        <hr />
        <h2>
          Total:{' '}
          <b>{reportAvaliableData + reportInvitedData + reportContactedData}</b>
        </h2>
      </SumContainer>
    </PageWrapper>
  );
};

export default SupplierBridesPage;
