import React, { useState } from 'react'
import { useFormState } from 'react-use-form-state'
import styled from 'styled-components'
import { navigate } from '@reach/router'
import { toast } from 'react-toastify'

import Arabesco from '../../images/arabescos.svg'
import { medias } from '../../utils/mediaQueries'
import { Button, SegmentList, Loader } from '../../components'
import { theme } from '../../styles/theme'
import { useStateValue } from '../../state'
import { hexToRgb } from '../../utils/hexToRgb'
import useFetch, { method } from '../../hooks/useFetch'
import { urls } from '../../services/urls'
import { bride } from '../../services/api'

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 2em;

  background-color: ${theme.colors.white};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 3px 10px 0px ${hexToRgb(theme.colors.grey2, 0.5)};
`

const AssetContainer = styled.div`
  @media ${medias.tabletMin} {
    background-size: contain;
  }

  background-image: url(${Arabesco});
  background-size: cover;
  background-repeat-x: repeat;
  width: 100%;
  height: 2em;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  ${({ bottom }) => bottom && `margin-top: 1em;`}
`

const RadioContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 3% 7%;
`

const TextContainer = styled.div`
  width: 100%;
`

const SegmentContainer = styled.div`
  width: 100%;
`

const ButtonsContainer = styled.section`
  display: flex;
  justify-content: space-around;
  margin-top: 0.5em;
  width: 100%;
`

const Text = styled.p`
  color: ${theme.colors.grey3};
  font-size: ${theme.fontSizeMed}px;
  @media ${medias.phoneMax} {
    font-size: ${theme.fontSizeSm}px;
  }
  padding-bottom: 3px;
`

const StyledForm = styled.form`
  width: 100%;
  padding: 0 1em;
`

const RadioLabel = styled.label`
  color: ${theme.colors.text_heading};
  font-size: ${theme.fontSizeSm}px;
  text-align: center;
  margin: 0;
`

const RadioInput = styled.input`
  margin: 0.2em 0.3em 0 0;
`

const StyledButton = styled(props => <Button {...props} />)`
  width: 40%;
  background-color: ${theme.colors.text_heading};
  border-color: ${theme.colors.text_heading};
  color: white;
  font-size: ${theme.fontSizeSm}px;
  margin-top: 2em;
`

const HiredOutForm = ({ stepBack }) => {
  const [segments, setSegments] = useState(null)
  const [selectedSegments, setSelectedSegments] = useState([])
  const [segmentsWithSuppliers, setSegmentsWithSuppliers] = useState(null)
  const [loading, setLoading] = useState(false)

  const [
    {
      forms: {
        brideWelcomeForm: { acceptTerms },
      },
    },
    dispatch,
  ] = useStateValue()

  useFetch({
    url: urls.siteContent.segments,
    method: method.GET,
    condition: () => !segments,
    callback: data => setSegments(data),
  })

  useFetch({
    url: urls.bridesContent.getSegmentsWithRegisteredSuppliers,
    method: method.GET,
    condition: () => !segmentsWithSuppliers,
    callback: data => setSegmentsWithSuppliers(data),
  })

  const [formState, { radio }] = useFormState(initialFormState, {
    withIds: true,
    onChange(e) {
      const { name, value } = e.target
      dispatch({
        type: 'changeBrideWelcomeForm',
        field: name,
        newText: value,
      })
    },
  })

  const initialFormState = {
    contact: 'phone',
  }

  const handleClick = () => {
    acceptTerms === 'acceptToggle'
      ? onSubmitForm()
      : toast.error('Aceite os termos de uso!')
  }

  const onResponseOk = () => {
    localStorage.removeItem('firstLogin')
    navigate('/app/noivos/inicio', { state: { firstLogin: true } })
  }

  const onSubmitForm = async () => {
    setLoading(true)
    let otherSegments = segmentsWithSuppliers
    otherSegments.forEach(item => {
      if (selectedSegments.includes(item)) {
        const index = otherSegments.indexOf(item)
        otherSegments.splice(index, 1)
      }
    })
    const body = {
      segmentIds: selectedSegments,
      otherSegments,
    }
    const response = await bride.submitSegmentsForm(body)
    setLoading(false)
    response.ok && response.data
      ? onResponseOk()
      : toast.error('Erro ao enviar formulário!')
  }

  const onClickSegment = ({ codSegmento }) => {
    let newSelectedSegments

    if (selectedSegments.includes(codSegmento)) {
      const i = selectedSegments.indexOf(codSegmento)
      selectedSegments.splice(i, 1)
      newSelectedSegments = [...selectedSegments]
    } else {
      newSelectedSegments = [...selectedSegments, codSegmento]
    }
    setSelectedSegments(newSelectedSegments)
  }

  const handleSegments = () => {
    const initialSegments = segments.filter(
      segment => segment.faseSegmento === 1
    )
    const mediumSegments = segments.filter(
      segment => segment.faseSegmento === 2
    )
    const finalSegments = segments.filter(segment => segment.faseSegmento === 3)

    return (
      <React.Fragment>
        <SegmentList
          title="Fase Inicial"
          items={initialSegments}
          onClick={item => onClickSegment(item)}
          selected={selectedSegments}
        />
        <SegmentList
          title="Fase Intermediária"
          items={mediumSegments}
          onClick={item => onClickSegment(item)}
          selected={selectedSegments}
        />
        <SegmentList
          title="Fase Final"
          items={finalSegments}
          onClick={item => onClickSegment(item)}
          selected={selectedSegments}
        />
      </React.Fragment>
    )
  }

  return (
    <Card>
      <AssetContainer />
      <StyledForm onSubmit={() => console.log(formState)}>
        <TextContainer>
          <Text>
            Marque os segmentos com os quais você já fechou contrato fora.
          </Text>
        </TextContainer>
        <SegmentContainer>{segments && handleSegments()}</SegmentContainer>
        <RadioContainer>
          <RadioInput {...radio('acceptTerms', 'acceptToggle')} />
          <RadioLabel>
            {' '}
            Li e Concordo com a{' '}
            <a href="/privacidade" target="_blank" rel="noopener noreferrer">
              Política de privacidade
            </a>{' '}
            dos noivos.
          </RadioLabel>
        </RadioContainer>
        {loading && <Loader />}
        <ButtonsContainer>
          <StyledButton text="VOLTAR" click={stepBack} />
          <StyledButton text="CONFIRMAR" click={handleClick} />
        </ButtonsContainer>
      </StyledForm>
      <AssetContainer bottom />
    </Card>
  )
}

export default HiredOutForm
